import React from 'react';
import './Header.scss'
import Nav from './Nav/connectedNav';
import User from './User/connectedUser';
import { Link, withRouter } from 'react-router-dom';


const Header = ({ configuration, props }) => {
  let header_style, logo_style_1, logo_style_2;
  let main_color = configuration.portal_main_color;
  let secondary_color = configuration.portal_secondary_color;
  if (main_color !== undefined) {
    header_style = {
      borderBottom: `3px solid ${main_color}`,
    };
    logo_style_1 = {
      color: main_color,
    };
  }
  if (secondary_color !== undefined) {
    logo_style_2 = {
      color: secondary_color,
    };
  }
  return (
    <header style={header_style}>
      <Link id='logo-wrap' to='/'>
        <img id='logo' src={configuration.logo_image_url || '/am-logo.png'} alt='AM Conservation Group Logo' />
        <div>
          <h3 style={logo_style_2}>{configuration.logo_text_line_1 || 'AM Conservation Group'}</h3>
          <h2 style={logo_style_1}>{configuration.logo_text_line_2 || 'Trade Ally Portal'}</h2>
        </div>
      </Link>
      <Nav props={props} />
      <User />
    </header>
  );
}

export default withRouter(Header);