import React from 'react';
import './QuantifiedItem.scss';
import { formatCurrency } from '../../../app/appHelpers';

const getPrice = (quantified_item) => {
  var price;
  if (quantified_item.quantity) {
    if (quantified_item.price) {
      price = formatCurrency(quantified_item.price * quantified_item.quantity);
    }
    else if (quantified_item.rate) {
      price = formatCurrency(quantified_item.rate * quantified_item.quantity);
    }
  }
  return price;
}

export const QuantifiedItemHeader = (items) => (
  <div className='quantified-item header'>
    <div className='item'>
      <div className='sku'>SKU</div>
      {/* <div className='sku'>Customer SKU</div> */}
      {/* <div className='image'>Image</div> */}
      <div className='label'>Label</div>
      <div className='category'>Category</div>
      <div className='rate'>Rate</div>
    </div>
    <div className='quantity'><div className='text'>Quantity</div></div>
    <div className='quantified-price'>Line Total</div>
  </div>
)

const QuantifiedItem = ({ quantified_item }) => (
  <div className='quantified-item'>
    <div className='item'>
      <div className='sku'>{(quantified_item.virtual_sku && quantified_item.virtual_sku !== '' ? quantified_item.virtual_sku : quantified_item.sku) || '--'}</div>
      {/* <div className='sku'>{quantified_item.customer_part_number || '--'}</div> */}
      {/* <div className='image'><img src={getDefaultImageSrc(quantified_item.image)} alt={quantified_item.label} /></div> */}
      <div className='label'>{quantified_item.label || quantified_item.item_display || '--'}</div>
      <div className='category'>{quantified_item.category ? quantified_item.category.name : '--'}</div>
      <div className='rate'>{formatCurrency(quantified_item.price) || formatCurrency(quantified_item.rate) || '--'}</div>
    </div>
    <div className='quantity'>{quantified_item.quantity || '--'}</div>
    <div className='quantified-price'>{getPrice(quantified_item) || '--'}</div>
  </div>
);

export const QuantifiedItemFooter = ({ quantity, total }) => (
  <div className='quantified-item footer'>
    <div className='item'>
      <div className='sku'>Sub-Total</div>
      <div className='label'></div>
      <div className='category'></div>
      {/* <div className='image'></div> */}
      <div className='rate'></div>
    </div>
    <div className='quantity'><div className='text'>{quantity}</div></div>
    <div className='quantified-price'>{formatCurrency(total)}</div>
  </div>
)

export default QuantifiedItem;