import {
  RECEIVE_CONFIGURATION_FROM_API,
  RECEIVE_CONFIGURATION_ERROR_FROM_API,
} from './configurationTypes';

import INITIAL_STATE from './configurationInitialState';

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case RECEIVE_CONFIGURATION_FROM_API: {
      return {
        ...state,
        ...action.configuration,
      }
    }
    case RECEIVE_CONFIGURATION_ERROR_FROM_API: {
      return {
        ...state,
        ...action.configuration
      }
    }
    default: {
      return state;
    }
  }
}