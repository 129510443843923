import { connect } from 'react-redux';
import Login from './Login';
import { getFriendlyErrorMessage } from '../authHelpers';

const getFrom = (ownProps) => {
  if(ownProps.history.location.state){
    return ownProps.history.location.state.from
  } else {
    return {
      pathname: '/'
    }
  }
}

const mapStateToProps = (state, ownProps) => ({
  is_authenticated: state.auth.is_authenticated,
  status: state.auth.status,
  redirect_to_referrer: state.auth.redirect_to_referrer,
  from: getFrom(ownProps),
  error_message: getFriendlyErrorMessage(state.auth.error),
  ownProps,
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);