import React from 'react';
import './OrderPreview.scss';
import QuantifiedItems from '../OrderItems/QuantifiedItem/QuantifiedItems';
import AddressPreview from '../../addresses/AddressPreview';
// import OrderTotals from '../OrderTotals/OrderTotals';
import { formatCurrency } from '../../app/appHelpers';
import { getSubtotal } from '../orderHelpers';
import content from '../../../content';

export const OrderPreview = ({ show, order, items, onConfirmOrder, onRejectConfirmOrder, onClickBackground, free_shipping_threshold, display_customer_sku, free_shipping_enabled, history }) => (
  show ? (
    <div id='order-preview-wrap' onClick={onClickBackground}>
      <div id='order-preview'>
        <h2>Order Preview</h2>
        <div id='details-addresses'>
          <div id='details-wrap'>
            {/* <h3>Order Details</h3> */}
            <div id='details'>
              <div className='order-date field'>
                <div>Customer: </div>
                <div>{order.customer_data.parent_company_name}</div>
              </div>
              <div className='order-date field'>
                <div>Order Date: </div>
                <div>{(new Date(order.date_created)).toLocaleDateString()}</div>
              </div>
              <div className='purchase-order-number field'>
                <div>{content.po_number_label}</div>
                <div>{order.purchase_order_number || '--'}</div>
              </div>
              <div className='customer-comments field'>
                <div>{content.memo_label}: </div>
                <div>{order.memo || '--'}</div>
              </div>
            </div>
          </div>
          <div id='addresses'>
            <div id='billing-address-wrap'>
              <h3>Billing Address</h3>
              <div id='billing-address'>
                <AddressPreview address={order.billing_address} />
              </div>
            </div>
            <div id='shipping-address-wrap'>
              <h3>Shipping Address</h3>
              <div id='shipping-address'>
                <AddressPreview address={order.shipping_address} />
              </div>
            </div>
          </div>
        </div>
        <div className='items'>
          {/* <h3>Items</h3> */}
          <QuantifiedItems quantified_items={order.items} display_customer_sku={display_customer_sku} />
          <div className='subtotal'>
            <h3>Subtotal</h3>
            <span className='subtotal-value'>{formatCurrency(getSubtotal(order, items))}</span>
          </div>
          {/* <OrderTotals order={order} items={items} estimate={true} free_shipping_threshold={free_shipping_threshold} free_shipping_enabled={getSubtotal(order, items) >= free_shipping_threshold} /> */}
          <center><i>Shipping and Tax charges will be shown on final invoice where applicable.</i></center>
        </div>
        <div className='actions'>
          <button className='cancel button' onClick={onRejectConfirmOrder}>Change Order</button>
          <button className='submit button' onClick={onConfirmOrder}><span role='img' aria-label='checkmark'>✅</span> Submit Order</button>
        </div>
      </div>
    </div>
  ) : null
);