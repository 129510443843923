import { connect } from 'react-redux';

import User from './User';

import { logout } from '../../../auth/authActions';

export const mapStateToProps = (state) => ({
  is_authenticated: state.auth.is_authenticated,
  user_data: state.auth.user_data,
});

export const mapDispatchToProps = (dispatch) => ({
  onClick: (e) => {
    dispatch(logout());
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(User);