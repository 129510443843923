import React from 'react';
import './Footer.scss'

const Footer = ({ main_color, secondary_color, primary_poc_name, primary_poc_email, primary_poc_phone, secondary_poc_name, secondary_poc_email, secondary_poc_phone }) => {
  let style;
  if(main_color){
    style = {
      backgroundColor: main_color,
    }
  }
  return (
    <footer style={style}>
      <div className='address'>
        <h3>Location</h3>
        <p>AM Conservation Group, Inc.</p>
        <p>1701 Charleston Regional Parkway, Suite A</p>
        <p>Charleston, SC 29492 USA</p>
      </div>

      <div className='hours'>
        <h3>Hours of Operation</h3>
        <p>Monday – Friday, 8:30 a.m. – 5:00 p.m. EST</p>
      </div>

      <div className='contact'>
        <h3>Contact</h3>

        <div>
          <p>Tel – <a href='tel:8885133005'>888-513-3005</a></p>
          <p>Fax – <a href='tel:8885133005'>843.971.1472</a>, <a href='tel:8885133005'>843.849.8242</a></p>
          <p><a href='mailto:marketplacesupport@amconservationgroup.com'>marketplacesupport@amconservationgroup.com</a></p>
        </div>
      </div>
    </footer>
  );
}

export default Footer;