import { connect } from 'react-redux';
import {
  updateQuantity,
  confirmOrder,
  updateOrderMemo,
  updatePurchaseOrderNumber,
  updateCustomerComments,
  updateCurrentShippingAddressId,
  updateCurrentBillingAddressId,
  resetStateWithDefaultAddressFields,
  removeItemFromCurrentOrder,
} from '../orderActions.js'
import OrderForm from './OrderForm';
import { getSubtotal } from '../orderHelpers';


export const mapStateToProps = (state, ownProps) => ({
  addresses: state.addresses,
  categories: state.categories,
  order: state.orders.byId[state.orders.currentOrderId],
  configuration: state.configuration,
  customer: state.auth.user_data,
  ownProps,
  free_shipping_threshold: 2500,
  subtotal: getSubtotal(state.orders.byId[state.orders.currentOrderId], state.products),
  display_free_shipping_threshold: true,
  display_free_shipping_difference: true,
  free_shipping_difference: 2500 - getSubtotal(state.orders.byId[state.orders.currentOrderId], state.products),
  display_free_shipping_difference_threshold: 2500 / 2,
  all_items: state.products,
  items: state.orders.byId[state.orders.currentOrderId].items,
  billing_address: state.addresses.byId[state.orders.byId[state.orders.currentOrderId].billing_address_id],
  shipping_address: state.addresses.byId[state.orders.byId[state.orders.currentOrderId].shipping_address_id],
  show_item_picker: state.orders.show_item_picker,
  primary_poc_name: state.configuration.primary_poc_name,
  primary_poc_email: state.configuration.primary_poc_email,
  primary_poc_phone: state.configuration.primary_poc_phone,
  secondary_poc_name: state.configuration.secondary_poc_name,
  secondary_poc_email: state.configuration.secondary_poc_email,
  secondary_poc_phone: state.configuration.secondary_poc_phone,
  order_status_map: {
    editing: 'Preview Order',
    confirming: 'Confirming Order',
    submitting: 'Submitting...',
    submitted: 'Submitted',
  }
});

export const mapDispatchToProps = (dispatch, getState) => ({
  onReset: (e) => {
    dispatch(resetStateWithDefaultAddressFields());
    // dispatch(resetState());
  },
  onClick: (e) => {
    e.preventDefault();
    let target = e.target.parentElement.querySelector('[data-action-type]');
    // target.value = Math.min(parseFloat(target.max), parseFloat(target.value) + parseFloat(e.target.dataset.direction) * parseFloat(target.step));
    target.value = parseFloat(target.value) + parseFloat(e.target.dataset.direction) * parseFloat(target.step);
    dispatch(updateQuantity(target.id, parseFloat(target.value)));
  },
  removeItemFromOrder: (e) => {
    e.preventDefault();
    var target = e.target;
    if(target.nodeName !== 'BUTTON'){
      target = e.target.parentNode;
    }
    dispatch(removeItemFromCurrentOrder(target.dataset.itemId));
  },
  onChangeForm: (e) => {
    // e.preventDefault();
  },
  onChangeShippingAddress: (e) => {
    dispatch(updateCurrentShippingAddressId(e.target.value));
  },
  onChangeBillingAddress: (e) => {
    dispatch(updateCurrentBillingAddressId(e.target.value));
  },
  onChangeQuantity: (e) => {
    e.preventDefault();
    let value = parseFloat(e.target.value);
    value = Math.max(0, value);
    if (!isNaN(value)) {
      dispatch(updateQuantity(e.target.id, value));
    }
  },
  onChangeOrderMemo: (e) => {
    dispatch(updateOrderMemo(e.target.id, e.target.value));
  },
  onChangePurchaseOrderNumber: (e) => {
    dispatch(updatePurchaseOrderNumber(e.target.id, e.target.value));
  },
  onChangeCustomerComments: (e) => {
    dispatch(updateCustomerComments(e.target.value));
  },
  onFocus: (e) => {
    // Select input text when clicked if input text is 0
    let target = e.target;
    // if (parseInt(e.target.value) === 0) {
    window.setTimeout((() => target.select()), 10);
    // }
  },
  onBlur: (e) => {
    let target = e.target;
    if(window.report_timeout === undefined){
      // window.clearTimeout(window.report_timeout);
      window.report_timeout = window.setTimeout((() => {
        if(target.reportValidity){
          target.reportValidity();
        }
        window.setTimeout(()=>{ window.report_timeout = undefined }, 500)
      }), 300);
    }
  },
  onSubmit: (e) => {
    e.preventDefault();
    // dispatch(requestTaxAndShippingCostsFromAPI());
    dispatch(confirmOrder());
    return false;
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(OrderForm);