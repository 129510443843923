import INITIAL_STATE from './categoryInitialState';
import { RESET_STATE } from '../app/appTypes';
export default (state = INITIAL_STATE, action) => {

  switch(action.type){
    case RESET_STATE: return INITIAL_STATE;
    default: {
      return state;
    }
  }

}