import { connect } from 'react-redux';
import Product from './Product';

export const mapStateToProps = (state, ownProps) => ({
  product: state.products.byId[ownProps.match.params.id]
});

export const mapDispatchToProps = (dispatch) => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(Product);