import {
  UPDATE_QUANTITY,
  RESET_STATE,
  UPDATE_ORDER_PURCHASE_ORDER_NUMBER,
  UPDATE_ORDER_MEMO,
  UPDATE_CUSTOMER_COMMENTS,
  REQUEST_CONFIRM_ORDER_FROM_USER,
  RECEIVE_CONFIRM_ORDER_CONFIRMATION_FROM_USER,
  RECEIVE_CONFIRM_ORDER_REJECTION_FROM_USER,
  REQUEST_SUBMIT_ORDER,
  RECEIVE_ORDERS_FROM_API,
  RECEIVE_SUBMITTED_ORDER_DATA,
  UPDATE_CURRENT_BILLING_ADDRESS_ID,
  UPDATE_CURRENT_SHIPPING_ADDRESS_ID,
  RECEIVE_TAX_AND_SHIPPING_COSTS_DATA,
  REQUEST_TAX_AND_SHIPPING_INFORMATION,
  ADD_ITEM_TO_CURRENT_ORDER,
  SHOW_ITEM_PICKER,
  HIDE_ITEM_PICKER,
  UPDATE_DEFAULT_SHIPPING_ADDRESS,
  UPDATE_DEFAULT_BILLING_ADDRESS,
  RECEIVE_ORDERS_ERROR_RESPONSE,
  REMOVE_ITEM_FROM_CURRENT_ORDER,
  RECEIVE_PO_VALUE_FROM_API,
  RECEIVE_SUCCESSFUL_ORDER_TEST_DATA,
} from './orderTypes';
import INITIAL_STATE from './orderInitialState';

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case RECEIVE_ORDERS_FROM_API: {
      return {
        byId: {
          new: state.byId.new,
          submitting: state.byId.submitting,
          ...action.orders.byId,
        },
        allIds: [
          'new',
          ...action.orders.allIds,
        ],
        currentOrderId: state.currentOrderId,
        loading: false,
      }
    }
    case RECEIVE_ORDERS_ERROR_RESPONSE: {
      return {
        byId: state.byId,
        allIds: state.allIds,
        currentOrderId: state.currentOrderId,
        loading: false,
        error: action.error,
      }
    }
    case UPDATE_QUANTITY: {
      return {
        ...state,
        byId: {
          ...state.byId,
          [state.currentOrderId]: {
            ...state.byId[state.currentOrderId],
            quantities: {
              ...state.byId[state.currentOrderId].quantities,
              [action.id]: action.value,
            },
          }
        }
      };
    }
    case REQUEST_CONFIRM_ORDER_FROM_USER: {
      return {
        ...state,
        byId: {
          ...state.byId,
          [state.currentOrderId]: {
            ...state.byId[state.currentOrderId],
            status: 'confirming',
          }
        }
      }
    }
    case RECEIVE_CONFIRM_ORDER_REJECTION_FROM_USER: {
      return {
        ...state,
        byId: {
          ...state.byId,
          [state.currentOrderId]: {
            ...state.byId[state.currentOrderId],
            status: 'editing',
          }
        }
      }
    }
    case RECEIVE_CONFIRM_ORDER_CONFIRMATION_FROM_USER: {
      return {
        ...state,
        byId: {
          ...state.byId,
          [state.currentOrderId]: {
            ...state.byId[state.currentOrderId],
            status: 'confirmed',
          }
        }
      }
    }
    case REQUEST_SUBMIT_ORDER: {
      return {
        ...state,
        byId: {
          ...state.byId,
          [state.currentOrderId]: {
            ...state.byId[state.currentOrderId],
            status: 'submitting',
            date_submitting_began: (new Date()).toJSON(),
            time_submitting_began: (new Date()).getTime()
          },
          submitting: {
            ...state.byId[state.currentOrderId],
            status: 'submitting',
            date_submitting_began: (new Date()).toJSON(),
            time_submitting_began: (new Date()).getTime()
          }
        }
      }
    }
    case RECEIVE_SUBMITTED_ORDER_DATA: {
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.data.id]: {
            ...state.byId[state.currentOrderId],
            id: action.data.id,
            status: 'submitted',
            sales_order_number: 'SO__________',
            date: (new Date()).toLocaleDateString(),
            date_submitted: (new Date()).toJSON(),
            time_submitted: (new Date()).getTime()
          }
        },
        allIds: [
          ...state.allIds,
          action.data.id
        ]
      }
    }
    case UPDATE_ORDER_PURCHASE_ORDER_NUMBER: {
      return {
        ...state,
        byId: {
          ...state.byId,
          [state.currentOrderId]: {
            ...state.byId[state.currentOrderId],
            purchase_order_number: action.value,
          }
        }
      }
    }
    case UPDATE_CURRENT_BILLING_ADDRESS_ID: {
      return {
        ...state,
        byId: {
          ...state.byId,
          [state.currentOrderId]: {
            ...state.byId[state.currentOrderId],
            billing_address_id: action.id,
          }
        }
      }
    }
    case UPDATE_CURRENT_SHIPPING_ADDRESS_ID: {
      return {
        ...state,
        byId: {
          ...state.byId,
          [state.currentOrderId]: {
            ...state.byId[state.currentOrderId],
            shipping_address_id: action.id,
          }
        }
      }
    }
    case UPDATE_ORDER_MEMO: {
      return {
        ...state,
        byId: {
          ...state.byId,
          [state.currentOrderId]: {
            ...state.byId[state.currentOrderId],
            memo: action.value,
          }
        }
      }
    }
    case UPDATE_CUSTOMER_COMMENTS: {
      return {
        ...state,
        byId: {
          ...state.byId,
          [state.currentOrderId]: {
            ...state.byId[state.currentOrderId],
            customer_comments: action.value,
          }
        }
      }
    }
    case REQUEST_TAX_AND_SHIPPING_INFORMATION: {
      return {
        ...state,
        byId: {
          ...state.byId,
          [state.currentOrderId]: {
            ...state.byId[state.currentOrderId],
            shipping_and_tax_status: 'requesting',
          }
        }
      }
    }
    case RECEIVE_TAX_AND_SHIPPING_COSTS_DATA: {
      return {
        ...state,
        byId: {
          ...state.byId,
          [state.currentOrderId]: {
            ...state.byId[state.currentOrderId],
            ...action.data,
            shipping_and_tax_status: 'received',
          }
        }
      }
    }
    case RECEIVE_PO_VALUE_FROM_API: {
      return {
        ...state,
        byId: {
          ...state.byId,
          [state.currentOrderId]: {
            ...state.byId[state.currentOrderId],
            purchase_order_number: action.po_value,
          }
        }
      }
    }
    case SHOW_ITEM_PICKER: {
      return {
        ...state,
        show_item_picker: true,
      }
    }
    case HIDE_ITEM_PICKER: {
      return {
        ...state,
        show_item_picker: false,
      }
    }
    case ADD_ITEM_TO_CURRENT_ORDER: {
      return {
        ...state,
        byId: {
          ...state.byId,
          [state.currentOrderId]: {
            ...state.byId[state.currentOrderId],
            quantities: {
              ...state.byId[state.currentOrderId].quantities,
              [action.id]: action.quantity,
            },
            items: {
              ...state.byId[state.currentOrderId].items,
              allIds: [
                ...state.byId[state.currentOrderId].items.allIds,
                action.id
              ]
            }
          }
        }
      }
    }
    case REMOVE_ITEM_FROM_CURRENT_ORDER: {
      return {
        ...state,
        byId: {
          ...state.byId,
          [state.currentOrderId]: {
            ...state.byId[state.currentOrderId],
            quantities: {
              ...state.byId[state.currentOrderId].quantities,
              [action.id]: 0,
            },
            items: {
              ...state.byId[state.currentOrderId].items,
              allIds: state.byId[state.currentOrderId].items.allIds.filter(id => id !== action.id)
            }
          }
        }
      }
    }
    case UPDATE_DEFAULT_SHIPPING_ADDRESS: {
      return {
        ...state,
        byId: {
          ...state.byId,
          [state.currentOrderId]: {
            ...state.byId[state.currentOrderId],
            shipping_address_id: action.address_id,
          }
        }
      }
    }
    case UPDATE_DEFAULT_BILLING_ADDRESS: {
      return {
        ...state,
        byId: {
          ...state.byId,
          [state.currentOrderId]: {
            ...state.byId[state.currentOrderId],
            billing_address_id: action.address_id,
          }
        }
      }
    }
    case RESET_STATE: {
      return {
        ...state,
        byId: {
          ...state.byId,
          new: {
            ...INITIAL_STATE.byId.new,
            shipping_address_id: action.default_shipping_address_id,
            billing_address_id: action.default_billing_address_id,
          }
        }
      }
    }
    default:
      return state;
  }
}