const getDate = ()=>{
  var date = new Date();
  date.setHours(date.getHours() - date.getTimezoneOffset() / 60);
  return date;
}

export default {
  currentOrderId: 'new',
  loading: true,
  show_item_picker: false,
  byId: {
    new: {
      id: 'new',
      purchase_order_number: '',
      memo: '',
      customer_comments: '',
      quantities: {},
      status: 'editing',
      shipping_address_id: undefined,
      billing_address_id: undefined,
      items: {
        byId: {},
        allIds: [],
      },
      date_created: getDate().toJSON(),
      date_modified: getDate().toJSON(),
      date_submitted: undefined,
      subtotal: 0,
      total: 0,
      tax_total: 0,
      shipping_total: 0,
      tax_rate: 0,
      shipping_rate: 0,
      shipping_and_tax_status: undefined,
    },
  },
  allIds: ['new'],
}