// Polyfills
import 'babel-polyfill';
import 'react-app-polyfill/ie11';
// import "es6-promise/auto";
import 'promise-polyfill/src/polyfill';
import 'whatwg-fetch';

// React imports
import React from 'react';
import ReactDOM from 'react-dom';

// Redux imports
import { Provider } from 'react-redux';
import configureStore from './store';

// App imports
import App from './components/app/App';
import './index.css';
import { requestAppDataFromAPI } from './components/app/appActions';
import { getCookieValue } from './components/app/appHelpers';
import { userIsLoggedIn, clearLocalData } from './components/auth/authActions';

// Create store
const store = configureStore();

// Render store
ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root')
);

// Expose store to window for debug
window.store = store;

// Load products if logged in
if (getCookieValue('session_data') !== undefined) {
  try {
    let session_data = JSON.parse(getCookieValue('session_data'));
    if(session_data.parent_id){
      store.dispatch(userIsLoggedIn(session_data));
      store.dispatch(requestAppDataFromAPI(session_data.parent_id));
    }
  } catch (e) {
    console.log('error loading session data', e);
    store.dispatch(clearLocalData());
  }
} else {
  store.dispatch(clearLocalData());
}