import {
  RECEIVE_PRODUCTS_FROM_API
} from './productTypes';
import { clearLocalData } from '../auth/authActions';
import { createProductFromNSItem } from './productHelpers';
import { API } from '../../config';
import { getCookieValue } from '../app/appHelpers';


export const requestProductsFromAPI = () => {
  return (dispatch) => {
    let xhr = new XMLHttpRequest();
    xhr.open('POST', API.ITEMS_ENDPOINT);
    xhr.withCredentials = true;
    xhr.onload = () => {
      let byId = {};
      JSON.parse(xhr.response).items.forEach(item => createProductFromNSItem(item) ? byId[item.id] = createProductFromNSItem(item) : null);
      let allIds = Object.keys(byId);
      let products = {
        byId,
        allIds,
      }
      dispatch(receiveProductsFromAPI(products));
    }
    xhr.onerror = (error) => {
      console.log('Error requesting products from API', error);
      if(error & error.hasOwnProperty('statusCode') && error.statusCode === 401){
        dispatch(clearLocalData());
      }
    }
    let session_csrf = getCookieValue('session_csrf');
    xhr.send(JSON.stringify({ session_csrf }));
  }
};

export const receiveProductsFromAPI = (products) => ({
  type: RECEIVE_PRODUCTS_FROM_API,
  products
});