export default {
  redirect_to_referrer: false,
  is_authenticated: false,
  validity: {
    password_pattern: "^(?=.*?[A-Z])(?=.*?[a-z]).{8,}$",
    length: undefined,
    upper: undefined,
    lower: undefined,
    match: undefined,
    digit: undefined,
    special: undefined,
  },
  user_data: {
    pathname: undefined
  },
};