export const getActionMessageFromPathname = (from) => {
  switch (from) {
    case '/': return `view the home page.`;
    case '/orders': return `view orders.`;
    case '/orders/new': return 'create a new order.';
    case '/profile': return 'view your profile page.';
    case '/products': return 'view products.';

    default: return !!from ? `view the page at ${from}.` : 'view this page.';
  }
}