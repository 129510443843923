export default {
  byId: {
    'custom-billing': {
      addressee: "",
      attention: "",
      city: "",
      country: "",
      delivery_appointment_required: false,
      full_state: "",
      id: "custom-billing",
      internalid: "custom-billing",
      is_default_billing: true,
      is_default_shipping: false,
      is_residential: false,
      label: "-- Custom Billing --",
      liftgate_required: false,
      line1: "",
      line2: "",
      line3: "",
      override_enabled: false,
      override_text: "",
      phone: "",
      ship_instructions: "",
      state: "",
      zip_code: "",
    },
  'custom-shipping':
    {
      addressee: "",
      attention: "",
      city: "",
      country: "",
      delivery_appointment_required: false,
      full_state: "",
      id: "custom-shipping",
      internalid: "custom-shipping",
      is_default_billing: false,
      is_default_shipping: true,
      is_residential: false,
      label: "-- Custom Shipping --",
      liftgate_required: false,
      line1: "",
      line2: "",
      line3: "",
      override_enabled: false,
      override_text: "",
      phone: "",
      ship_instructions: "",
      state: "",
      zip_code: "",
    },
  },
  allIds: [],
  default_shipping_address_id: undefined,
  default_billing_address_id: undefined,
}