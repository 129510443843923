import { connect } from 'react-redux';
import Nav from './Nav';

export const mapStateToProps = (state, ownProps) => ({
  current: ownProps.props.location.pathname.split('/').filter(p => p !== '')[0],
  main_color: state.configuration.portal_main_color,
  secondary_color: state.configuration.portal_secondary_color,
});

export const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(Nav);