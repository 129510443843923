import Header from './Header';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

export const mapStateToProps = (state, ownProps)=>({
  configuration: state.configuration,
  props: ownProps,
});

export const mapDispatchtoProps = (dispatch)=>({

});

export default withRouter(connect(mapStateToProps, mapDispatchtoProps)(Header));