import React from 'react';
import { Link } from 'react-router-dom';
// import CurrentOrders from '../orders/CurrentOrders';
import './Home.scss';

const Home = ({ configuration }) => (
  <div className='home'>
    <h1>Welcome!</h1>
    <h2>Welcome to the {configuration.logo_text_line_1} Trade Ally Ordering Portal</h2>
    <Link to='/orders'><span role='img' aria-label='checkmark'>📁</span> View All Orders</Link>
    <Link to='/orders/new'><span role='img' aria-label='checkmark'>➕</span> Create New Order</Link>
    {/* <Link to='/profile'>Profile</Link> */}
    <a href={configuration.homepage_url} target='_blank' rel="noopener noreferrer"><span role='img' aria-label='checkmark'>🌎</span> Visit {configuration.homepage_url}</a>
  </div>
)

export default Home;