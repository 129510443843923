import { connect } from 'react-redux';
import Footer from './Footer';

export const mapStateToProps = (state)=>({
  main_color: state.configuration.portal_main_color,
  secondary_color: state.configuration.portal_secondary_color,
});

export const mapDispatchToProps = (dispatch)=>({

});

export default connect(mapStateToProps, mapDispatchToProps)(Footer);