import { combineReducers } from 'redux';

import auth from './components/auth/authReducer';
import orders from './components/orders/orderReducer';
import addresses from './components/addresses/addressReducer';
import categories from './components/categories/categoryReducer';
import products from './components/products/productReducer';
import configuration from './components/configuration/configurationReducer';

export default combineReducers({
  auth,
  addresses,
  categories,
  configuration,
  orders,
  products,
});