import {
  RECEIVE_LOGIN_DATA,
  RECEIVE_LOGIN_ERROR,
  USER_IS_LOGGED_IN,
  USER_IS_LOGGED_OUT,
  UPDATE_AUTH_STATUS,
  REQUEST_LOGIN,
  UPDATE_VALIDITY,
  RECEIVE_CHANGED_PASSWORD_RESPONSE,
} from './authTypes';
import { requestAppDataFromAPI } from '../app/appActions';
import { API } from '../../config';
import { getCookieValue } from '../app/appHelpers';

// FIXME: normalize verb tense across actions

export const requestLogin = (email) => ({
  type: REQUEST_LOGIN,
  email
});

export const receiveLoginData = (response) => ({
  type: RECEIVE_LOGIN_DATA,
  response,
});

export const receiveLoginError = (error) => ({
  type: RECEIVE_LOGIN_ERROR,
  error,
});

export const userIsLoggedIn = (user_data) => ({
  type: USER_IS_LOGGED_IN,
  user_data,
});

export const userIsLoggedOut = (userid) => ({
  type: USER_IS_LOGGED_OUT,
  userid,
});

export const updateAuthStatus = (status) => ({
  type: UPDATE_AUTH_STATUS,
  status,
})

export const logout = () => {
  return (dispatch) => {
    dispatch(clearLocalData());
    dispatch(userIsLoggedOut());
    window.setTimeout(()=>{
      window.location.href = '/';
    }, 1000)
  }
}

export const clearLocalData = () => {
  return (dispatch) => {
    document.cookie = 'session_data=;Max-Age=0';
  }
}

export const updateValidity = (aspect, is_valid) => ({
  type: UPDATE_VALIDITY,
  aspect,
  is_valid,
});

export const receiveChangedPasswordResponse = () => ({
  type: RECEIVE_CHANGED_PASSWORD_RESPONSE,
});

export const changePassword = (email, password, new_password, new_password_confirm, history, router_state) => {
  return (dispatch, getState) => {
    // Start by updating auth status and dispatching a request login action
    dispatch(updateAuthStatus('requesting'));
    // dispatch(requestLogin(email));
    // Then fetch login data from the API server
    fetch(API.CHANGE_PASSWORD_ENDPOINT, {
      method: 'POST',
      // crossDomain: true,
      credentials: 'include',
      // xhrFields: { withCredentials: true },
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email,
        password,
        session_csrf: getCookieValue('session_csrf'),
        payload: {
          current_password: password,
          new_password,
          new_password_confirm,
        }
      })
    }).then(response => {
      // Check for OK response and throw error if found
      if (!response.ok) {
        return response.text().then(error_message => {
          throw new Error(error_message);
        })
      }
      else {
        // Return jsonified response
        return response.json()
      }
    }).then(data => {
      // Update auth status
      dispatch(updateAuthStatus('received'));
      // Received changed password response
      dispatch(receiveChangedPasswordResponse());
      let customer_data = getState().auth.user_data;
      dispatch(userIsLoggedIn(customer_data));
      // Retrieve data from API
      localStorage.customer_data = JSON.stringify(customer_data);
      // dispatch(userIsLoggedIn(data.customer));
      if (router_state && router_state.from) {
        history.push(router_state.from);
      } else {
        history.push('/profile');
      }
    }).catch(e => {
      console.log('error', e);
      // Received login error
      dispatch(receiveLoginError(e));
      // Clean up local storage
      dispatch(clearLocalData());
    });
  }
}

export const login = (email, password, remember_me, history, router_state) => {
  return (dispatch) => {
    // Start by updating auth status and dispatching a request login action
    dispatch(updateAuthStatus('requesting'));
    dispatch(requestLogin(email));
    // Then fetch login data from the API server
    fetch(API.LOGIN_ENDPOINT, {
      method: 'POST',
      // crossDomain: true,
      credentials: 'include',
      // xhrFields: { withCredentials: true },
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email,
        password,
        remember_me,
      })
    }).then(response => {
      // Check for OK response and throw error if found
      if (!response.ok) {
        return response.text().then(error_message => {
          throw new Error(error_message);
        })
      }
      else {
        // Return jsonified response
        return response.json()
      }
    }).then(data => {
      // Update auth status
      dispatch(updateAuthStatus('received'));
      // Received login response
      dispatch(receiveLoginData(data));
      dispatch(userIsLoggedIn(data));
      // Retrieve data from API
      dispatch(requestAppDataFromAPI(data.parent_id));
      if (router_state && router_state.from) {
        history.push(router_state.from);  
      } else {
        history.push('/');
      }
    }).catch(e => {
      // Received login error
      dispatch(receiveLoginError(e));
      // Clean up local storage
      dispatch(clearLocalData());
    });
  }
};