export const values = (obj) => {
  if (Object.hasOwnProperty('values')) {
    return Object.values(obj);
  } else {
    let result = [];
    for (let key in obj) {
      result.push(obj[key]);
    }
    return result;
  }
}

export const formatCurrency = (currency) => !isNaN(parseFloat(currency)) ? parseFloat(currency).toLocaleString('en-US', { style: 'currency', currency: 'USD' }) : null;

export const getCookieValue = (key)=> key === undefined ? key : decodeURIComponent(document.cookie.replace(new RegExp("(?:(?:^|.*;)\\s*" + encodeURIComponent(key).replace(/[-.+*]/g, "\\$&") + "\\s*\\=\\s*([^;]*).*$)|^.*$"), "$1")) || null;

export const clearCookies = ()=> delete document.cookie;

export const getDefaultImageSrc = (src)=> src ? src : '/picture-icon.png'

export const formatCompanyName = (input) => input ? input.replace(/^[0-9]{1,10}/, '').replace(/^ /, '') : null;