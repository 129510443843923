import {
  UPDATE_ADDRESSEE,
  UPDATE_ATTENTION,
  UPDATE_PHONE,
  UPDATE_ADDRESS_LINE_1,
  UPDATE_ADDRESS_LINE_2,
  UPDATE_ZIP_CODE,
  UPDATE_CITY,
  UPDATE_STATE,
  RECEIVE_ADDRESSES_FROM_API,
} from './addressTypes';

import INITIAL_STATE from './addressInitialState';

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case RECEIVE_ADDRESSES_FROM_API: {
      return {
        byId:{
          ...state.byId,
          ...action.addresses.byId
        },
        allIds: [
          ...state.allIds,
          ...action.addresses.allIds,
        ],
        default_shipping_address_id: action.addresses.allIds.filter(id=> action.addresses.byId[id].is_default_shipping === true)[0],
        default_billing_address_id: action.addresses.allIds.filter(id=> action.addresses.byId[id].is_default_billing === true)[0],
      }
    }
    case UPDATE_ADDRESSEE: {
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.address_id]: {
            ...state.byId[action.address_id],
            addressee: action.value
          }
        }
      }
    }
    case UPDATE_ATTENTION: {
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.address_id]: {
            ...state.byId[action.address_id],
            attention: action.value
          }
        }
      }
    }
    case UPDATE_PHONE: {
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.address_id]: {
            ...state.byId[action.address_id],
            phone: action.value
          }
        }
      }
    }
    case UPDATE_ADDRESS_LINE_1: {
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.address_id]: {
            ...state.byId[action.address_id],
            line1: action.value
          }
        }
      }
    }
    case UPDATE_ADDRESS_LINE_2: {
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.address_id]: {
            ...state.byId[action.address_id],
            line2: action.value
          }
        }
      }
    }
    case UPDATE_ZIP_CODE: {
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.address_id]: {
            ...state.byId[action.address_id],
            zip_code: action.value
          }
        }
      }
    }
    case UPDATE_CITY: {
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.address_id]: {
            ...state.byId[action.address_id],
            city: action.value
          }
        }
      }
    }
    case UPDATE_STATE: {
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.address_id]: {
            ...state.byId[action.address_id],
            state: action.value
          }
        }
      }
    }
    default: {
      return state;
    }
  }
}