import { connect } from 'react-redux';
import Profile from './Profile';

export const mapStateToProps = (state)=>({
  user_data: state.auth.user_data,
  addresses: state.addresses.allIds.map(id=>state.addresses.byId[id]),
});

export const mapDispatchtoProps = (dispatch)=>({

});

export default connect(mapStateToProps, mapDispatchtoProps)(Profile);