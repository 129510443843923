import React from 'react';
import LoadingAnimation from '../../app/animations/LoadingAnimation';
import './ChangePassword.scss';

const ChangePassword = ({ email, onChange, onSubmit, onBlur, onFocus, validity, status, forced })=> {
  if(status === 'requesting'){
    return (
     <div className='change-password requesting'>
      <h1>Change Password</h1>
      <h2>Changing your password, please wait<LoadingAnimation /></h2>
     </div> 
    );
  } else {
    return (
      <div className='change-password'>
        { forced ?
          <div>
            <h1>Welcome!</h1>
            <h2>Our system requires you to change your password.</h2> 
          </div>
          :
          <h1>Change Password</h1>
        }
        <form action='#' onChange={onChange} onSubmit={onSubmit} onBlur={onBlur} onFocus={onFocus}>
          <div className='field'>
            <label htmlFor='email' className='required'>Email Address: </label>
            <input id='email' name='email' type='email' placeholder='user@example.com' defaultValue={email} disabled required />
          </div>
          <div className='field'>
            <label htmlFor='current-password' className='required'>Current password: </label>
            <input id='current-password' name='current_password' type='password' placeholder='*********' min={8} autoFocus required />
          </div>
          <div className='new-password-wrap'>
            <div className='fields'>
              <div className='field'>
                <label htmlFor='new-password' className='required'>New Password: </label>
                <input id='new-password' name='new_password' type='password' min={8} placeholder='*********' pattern={validity.password_pattern} required />
              </div>
              <div className='field'>
                <label htmlFor='new-password-confirm' className='required'>Re-type New Password: </label>
                <input id='new-password-confirm' name='new_password_confirm' type='password' placeholder='*********' data-match-id='new-password' min={8}  pattern={validity.password_pattern} required />
              </div>
            </div>
            <div className='instructions'>
              <b>Passwords must:</b>
                <ul id='password-requirements'>
                  <li data-is-valid={validity.length}>Be at least 8 characters</li>
                  <li data-is-valid={validity.upper}>Contain at least one Upper Case Letter</li>
                  <li data-is-valid={validity.lower}>Contain at least one Lower Case Letter</li>
                  <li data-is-valid={validity.match}>Match</li>
                </ul>
            </div>
          </div>
          <div className='actions'>
            <input type='submit' value='Change Password' />
          </div>
        </form>
      </div>
    );
  }
};

export default ChangePassword;