import React from 'react';
import './AddressForm.scss';


const AddressForm = ({
  kind,
  address,
  onChangeAttention,
  onChangeAddressee,
  onChangePhone,
  onChangeZipCode,
  onChangeAddressLine1,
  onChangeAddressLine2,
  onChangeCity,
  onChangeState }
) => (
    <fieldset
      data-address-id={address.id}>
      <div className='required field'>
        <label htmlFor={[kind, 'addressee'].join('-')}>Addressee</label>
        <input
          id={[kind, 'addressee'].join('-')}
          autoComplete='addressee'
          type='text'
          // required={true}
          onChange={onChangeAddressee}
          data-address-kind={kind}
          value={address.addressee} />
      </div>
      <div className='field'>
        <label htmlFor={[kind, 'attention'].join('-')}>Attention</label>
        <input
          id={[kind, 'attention'].join('-')}
          autoComplete='attention'
          type='text'
          onChange={onChangeAttention}
          data-address-kind={kind}
          value={address.attention} />
      </div>
      <div className='required field'>
        <label htmlFor={[kind, 'address-line-1'].join('-')}>Address Line 1</label>
        <input
          id={[kind, 'address-line1'].join('-')}
          autoComplete='address-line1'
          type='text'
          required={true}
          onChange={onChangeAddressLine1}
          data-address-kind={kind}
          value={address.line1} />
      </div>
      <div className='field'>
        <label htmlFor={[kind, 'address-line-2'].join('-')}>Address Line 2</label>
        <input
          id={[kind, 'address-line2'].join('-')}
          autoComplete='address-line2'
          type='text'
          onChange={onChangeAddressLine2}
          data-address-kind={kind}
          value={address.line2} />
      </div>
      <div className='required field'>
        <label htmlFor={[kind, 'zip-code'].join('-')}>Zip Code</label>
        <input
          id={[kind, 'zip-code'].join('-')}
          autoComplete='zip-code'
          type='text'
          pattern='(^\d{5}$)|(^\d{5}-\d{4}$)'
          required={true}
          onChange={onChangeZipCode}
          data-address-kind={kind}
          defaultValue={address.zip_code} />
      </div>
      <div className='required field'>
        <label htmlFor={[kind, 'city'].join('-')}>City</label>
        <input
          id={[kind, 'city'].join('-')}
          type='text'
          required={true}
          onChange={onChangeCity}
          data-address-kind={kind}
          value={address.city}
        />
      </div>
      <div className='required field'>
        <label htmlFor={[kind, 'state'].join('-')}>State</label>
        <input
          id={[kind, 'state'].join('-')}
          type='text'
          required={true}
          onChange={onChangeState}
          data-address-kind={kind}
          value={address.state} />
      </div>
      <div className='required field'>
        <label htmlFor={[kind, 'phone'].join('-')}>Phone</label>
        <input
          id={[kind, 'phone'].join('-')}
          autoComplete='phone'
          type='text'
          required={true}
          onChange={onChangePhone}
          data-address-kind={kind}
          value={address.phone} />
      </div>
    </fieldset>
  );

export default AddressForm;