import React from 'react';
import './AddressPreview.scss';
import LoadingAnimation from '../app/animations/LoadingAnimation';

const AddressPreview = ({ address, loading, address_text, show_ship_instructions }) => {
  if (address === undefined) {
    if(loading) {
      return (
        <div className='address-preview loading'><LoadingAnimation /></div>
      )
    } else if(address_text){
      return (
        <div className='address-preview override'>
          {address_text.split(/<br>|\n/).map(ele => <div>{ele}</div>)}
        </div>      
      );
    }
    return (
      <div className='address-preview'><i>No Address Found.</i></div>
    );
  }
  if (address.override_enabled) {
    return (
      <div className='address-preview override'>
        {address.override_text.split('<br>').map(ele => <div>{ele}</div>)}
      </div>
    )
  }
  else {
    return (
      <div className='address-preview'>
        <div className='address'>
          <div className='attention'>{address.attention}</div>
          <div className='addressee'>{address.addressee}</div>
          <div className='line1'>{address.line1}</div>
          <div className='line2'>{address.line2}</div>
          <div className='city-state-zip'>
            <div className='city'>{address.city}</div>
            <div className='state'>{address.state}</div>
            <div className='zip_code'>{address.zip_code}</div>
          </div>
          <div className='country'>{address.country || 'United States'}</div>
          <div className='phone'>{address.phone}</div>
        </div>
        {
          address.ship_instructions && show_ship_instructions &&
          <div className='instructions'><b>Instructions</b>: <br />{address.ship_instructions}</div>
        }
      </div>
    )
  }
}

export default AddressPreview;