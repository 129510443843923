import {
  UPDATE_AUTH_STATUS,
  RECEIVE_LOGIN_DATA,
  RECEIVE_LOGIN_ERROR,
  USER_IS_LOGGED_IN,
  USER_IS_LOGGED_OUT,
  UPDATE_VALIDITY,
  RECEIVE_CHANGED_PASSWORD_RESPONSE,
} from './authTypes';

import INITIAL_STATE from './authInitialState';


export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UPDATE_AUTH_STATUS: {
      return {
        ...state,
        status: action.status
      }
    }
    case USER_IS_LOGGED_OUT: {
      return {
        ...state,
        is_authenticated: false,
        user_data: {},
      }
    }
    case USER_IS_LOGGED_IN: {
      return {
        ...state,
        is_authenticated: true,
        user_data: action.user_data,
      }
    }
    case RECEIVE_LOGIN_DATA: { // Should combine this and user is logged in
      return {
        ...state,
        is_authenticated: true,
        error: null,
      }
    }
    case RECEIVE_LOGIN_ERROR: {
      return {
        ...state,
        is_authenticated: false,
        status: 'error',
        error: action.error
      }
    }
    case UPDATE_VALIDITY: {
      return {
        ...state,
        validity: {
          ...state.validity,
          [action.aspect]: action.is_valid,
        }
      }
    }
    case RECEIVE_CHANGED_PASSWORD_RESPONSE: {
      return {
        ...state,
        is_authenticated: true,
        status: 'received',
        user_data: {
          ...state.user_data,
          force_password_change: false,
        }
      }
    }
    default:
      return state;
  }
}