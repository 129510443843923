import Home from './Home';
import { connect } from 'react-redux';

export const mapStateToProps = (state)=>({
  configuration: state.configuration,
});

export const mapDispatchToProps = (dispatch)=>({

});

export default connect(mapStateToProps, mapDispatchToProps)(Home);