import React from 'react';
import { UPDATE_QUANTITY } from '../../orderTypes';
import { formatCurrency, getDefaultImageSrc } from '../../../app/appHelpers';
import OrderItemInputHeader from './OrderItemInputHeader';
import { formatCategory } from '../../../categories/categoryHelper';

// const InvalidInputError = new Error('Invalid input supplied');

const ItemFields = ({ items, all_items, categories, onChange, onClick, onFocus, onBlur, removeItemFromOrder, order }) => {
  let fields;
  if (items !== undefined && items.length === 0) {
    fields = [];
  }
  else if (all_items === undefined || all_items.allIds === undefined || all_items.allIds.length === 0) {
    fields = [<div className='loading' key='loading'>Loading Items...</div>];
  }
  else {
    fields = items.map(id => {
      // move to connected join function
      let item = all_items.byId[id];
      let quantity = order.quantities[id] || 0;
      return (
        <div
          className='field'
          data-current-quantity={quantity | 0}
          key={item.key}>
          <div className='item-category-wrap'>
            <span
              data-category={item.category_id}
              className='item-category'>
              {formatCategory(item.category, false)}
            </span>
          </div>
          <div className='item-image-wrap'>
            <img
              src={getDefaultImageSrc(item.image)}
              alt={item.name} />
          </div>
          {/* <div className='item-description'>{item.sales_description}</div> */}
          {/* <div className='item-name-wrap'>
            <label htmlFor={id}>{item.label}</label>
          </div> */}
          <div className='customer-sku-wrap'>{item.customer_part_number || '--'}</div>
          <div className='item-description-wrap'>{item.sales_description}</div>
          <div className='item-name-wrap'>{item.label}</div>
          <div className='item-trm-number'>{item.trm_number}</div>
          <div className='item-unit-price-wrap'>{formatCurrency(item.price)}</div>
          <div className='case-quantity'>{item.case_quantity || '--'}</div>
          <div className='item-quantity-wrap'>
            <input
              type='number'
              id={id}
              className='item-quantity'
              name={item.key}
              defaultValue={quantity}
              data-action-type={UPDATE_QUANTITY}
              onFocus={onFocus}
              onBlur={onBlur}
              onChange={onChange}
              step={item.quantity_step || 1}
              min={0}
              // max={item.quantity_max}
               />
            <button type='button' tabIndex="-1" data-direction={-1} onClick={onClick} disabled={parseInt(quantity) <= parseInt(item.quantity_min)}>-</button>
            <button type='button' tabIndex="-1" data-direction={1} onClick={onClick} disabled={parseInt(quantity) >= parseInt(item.quantity_max)}>+</button>
            <button type='button' tabIndex="-1" className='remove' onClick={removeItemFromOrder} data-item-id={item.id}><span role='img' aria-label='close'>&times;</span></button>
          </div>
          <div className='item-line-total-wrap'>
            <span className='item-line-total-value' data-quantity={quantity}>{quantity > 0 ? formatCurrency(item.price * quantity) : '--'}</span>
          </div>
        </div>
      );
    });
  }
  fields.unshift(<OrderItemInputHeader key={'heading'} />);
  return fields;
}

export default ItemFields;