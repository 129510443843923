import { connect } from 'react-redux';
import ItemPicker from './OrderItemModalPicker';
import { addItemToCurrentOrder, showItemPicker, hideItemPicker } from '../../orderActions';

const mapStateToProps = (state) => ({
  items: state.orders.byId[state.orders.currentOrderId].items.allIds,
  all_items: state.products,
  categories: state.categories,
  order: state.orders.byId[state.orders.currentOrderId],
  show_item_picker: state.orders.show_item_picker && state.orders.byId[state.orders.currentOrderId].items.allIds.length < state.products.allIds.length,
});

const addItemsToCurrentOrder = (nodeList, dispatch) => {
  var items_to_add = {};
  let can_exit = true;
  Array.prototype.forEach.call(nodeList, (input) => {
    if(can_exit){
    if (input.value > 0) {
      if (input.checkValidity) {
        if (input.checkValidity()) {
          items_to_add[input.dataset.itemId] = input.value;
        } else if (input.reportValidity) {
          can_exit = false;
          input.reportValidity();
        } else {
          can_exit = false;
          alert('You entered quantity of ' + input.value + ' for ' + input.dataset.itemSku + ', case size for this item is ' + input.step + '. Please enter a valid quantity.');
        }
      } else {
        if (parseInt(input.value) % parseInt(input.step) === 0) {
          items_to_add[input.dataset.itemId] = input.value;
        } else {
          can_exit = false;
          alert('You entered quantity of ' + input.value + ' for ' + input.dataset.itemSku + ', case size for this item is ' + input.step + '. Please enter a valid quantity.');
        }
      }
    }
}
  });
  if(can_exit && Object.keys(items_to_add).length){
    Object.keys(items_to_add).forEach(id=> dispatch(addItemToCurrentOrder(id, items_to_add[id])));
    dispatch(hideItemPicker());
  }
}

const mapDispatchToProps = (dispatch) => ({
  onAddItem: (e) => {
    e.preventDefault();
    dispatch(showItemPicker());
  },
  onFocusQuantity: (e) => {
    let target = e.target;
    window.setTimeout((() => target.select()), 10);
  },
  onSelectItem: (e) => {
    let quantity = document.getElementById(e.target.dataset.quantityTarget).value;
    if (parseInt(quantity) <= 0) {
      quantity = document.getElementById(e.target.dataset.quantityTarget).step;
    }
    if (quantity === undefined || parseInt(quantity) <= 0) {
      quantity = 1;
    }
    dispatch(addItemToCurrentOrder(e.target.dataset.itemId, quantity));
    // dispatch(hideItemPicker());
  },
  onExitItemPicker: (e) => {
    if (e.target.className.match(/item-picker-modal|cancel/)) {
      e.preventDefault();
      dispatch(hideItemPicker());
    } else if (e.target.className.match(/ok/)) {
      e.preventDefault();
      addItemsToCurrentOrder(document.querySelectorAll('.item-picker input.quantity'), dispatch);
    }
  },
  onKeyUp: (e) => {
    // IE11
    if(!!window.MSInputMethodContext && !!document.documentMode){
      if(e.keyCode === 38){ // up
        e.target.value = parseInt(e.target.value) + parseInt(e.target.step);
        e.target.select();
      } else if(e.keyCode === 40){
        if(e.target.value > 0){
          e.target.value = parseInt(e.target.value) - parseInt(e.target.step);
          e.target.select();
        }
      }
    }
    if (e.keyCode === 13) {
      e.preventDefault();
      addItemsToCurrentOrder(document.querySelectorAll('.item-picker input.quantity'), dispatch);
    }
  },
  onUpdateQuantity: (e) => {
    e.preventDefault();
    // dispatch((e.target.dataset.itemId, e.target.dataset.quantityStep));
  },
  filterItems: (e) => {
    Array.prototype.forEach.call(document.querySelectorAll('.item-picker .item'), (item => (e.target.value === '' || item.innerText.toLowerCase().match(e.target.value.toLowerCase())) ? item.style.display = '' : item.style.display = 'none'));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ItemPicker);
