import { requestAddressesFromAPI } from '../addresses/addressActions';
import { requestProductsFromAPI } from '../products/productActions';
import { requestOrdersFromAPI } from '../orders/orderActions';
import { requestConfigurationFromAPI } from '../configuration/configurationActions';


export const requestAppDataFromAPI = ()=>(
  (dispatch)=>{
    dispatch(requestAddressesFromAPI());
    dispatch(requestConfigurationFromAPI());
    dispatch(requestProductsFromAPI());
    dispatch(requestOrdersFromAPI());
  }
)