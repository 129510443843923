import React from 'react';
import './OrderForm.scss'
// import OrderTotals from '../OrderTotals/OrderTotals';
import OrderItemInputs from '../OrderItems/OrderItemInput/OrderItemInputs';
import ItemPicker from '../OrderItems/OrderItemModalPicker/connectedOrderItemModalPicker';
import LoadingAnimation from '../../app/animations/LoadingAnimation';
import OrderPreview from '../OrderPreview/connectedOrderPreview';
import content from '../../../content';

import {
  formatCurrency,
  values
} from '../../app/appHelpers';
import {
  getSubtotal,
} from '../orderHelpers';
import AddressPreview from '../../addresses/AddressPreview';
import AddressForm from '../../addresses/form/connectedAddressForm';
import { getAddressLabel } from '../../addresses/addressHelpers';

const OrderForm = ({
  configuration,
  addresses,
  onSubmit,
  onChangeForm,
  onChangeQuantity,
  onChangeOrderMemo,
  onChangePurchaseOrderNumber,
  onChangeShippingAddress,
  onChangeBillingAddress,
  default_billing_address_id,
  default_shipping_address_id,
  free_shipping_threshold,
  display_free_shipping_threshold,
  display_free_shipping_difference,
  free_shipping_difference,
  display_free_shipping_difference_threshold,
  removeItemFromOrder,
  order,
  show_item_picker,
  all_items,
  ownProps,
  order_status_map,
  billing_address,
  shipping_address,
  subtotal,
  customer,
  categories,
  onClick,
  onFocus,
  onBlur,
  primary_poc_name,
  primary_poc_email,
  primary_poc_phone,
  secondary_poc_name,
  secondary_poc_email,
  secondary_poc_phone,
  onReset }) => {
  if (order.status === 'editing') {
    return (
      <div className='order-form-wrap' data-status={order.status}>
        <h1>New Sales Order</h1>
        <form
          className='order-form'
          onSubmit={onSubmit}
          onReset={onReset}
          onChange={onChangeForm}>
          {/* <OrderFormShippingFields onChangeZipCode={onChangeZipCode} order={order} /> */}
          <div className='info-fields-addresses-wrap'>
            <div className='info-fields'>
              <h2>{content.order_info_label}</h2>
              <div className='customer field'>
                <label htmlFor='order-form-customer-name'>{content.customer_label}</label>
                <input id='order-form-customer-name' type='text' value={customer.parent_company_name} disabled />
              </div>
              <div className='date field'>
                <label htmlFor='order-form-order-date'>{content.order_date_label}</label>
                <input id='order-form-order-date' type='date' disabled value={new Date(order.date_created).toISOString().substr(0, 10)} />
              </div>
              <div className='po field'>
                <label htmlFor='order-form-po-number'>{content.po_number_label}</label>
                <input
                  id='order-form-po-number'
                  type='text'
                  onChange={onChangePurchaseOrderNumber}
                  disabled={configuration.locked_po_value.length}
                  defaultValue={configuration.default_po_value}
                  value={order.purchase_order_number} />
              </div>
              {/* <div className='memo field'>
                <label htmlFor='order-form-memo'>Memo</label>
                <input
                  id='order-form-memo'
                  type='text'
                  onChange={onChangeOrderMemo}
                  value={order.memo} />
              </div>*/}
              <div className='customer-comments field'>
                <label htmlFor='order-form-comments'>{content.memo_label}</label>
                <textarea
                  id='order-form-comments'
                  onChange={onChangeOrderMemo}
                  value={order.memo}></textarea>
              </div>
            </div>
            <div id='addresses'>
              <div className='address-wrap'>
                <h2><label htmlFor='order-form-billing-address'>{content.billing_address_label}</label></h2>
                <div className='address-select-wrap'>
                  <select disabled={configuration.lock_billing_address} id='order-form-billing-address' onChange={onChangeBillingAddress} value={order.billing_address_id}>
                    {configuration.allow_billing_override &&
                      <option value='custom-billing'>-- Custom Billing Address --</option>
                    }
                    {addresses.allIds.map(id => (
                      <option key={id} value={id}>{getAddressLabel(addresses.byId[id])}</option>
                    ))}
                  </select>
                  {billing_address ?
                    (billing_address.id === 'custom-billing' ?
                      <AddressForm kind='billing' address={billing_address} />
                      :
                      <AddressPreview address={billing_address} />
                    )
                    :
                    <h4>Loading Billing Address...</h4>
                  }
                </div>
              </div>
              <div className='address-wrap'>
                <h2><label htmlFor='order-form-shipping-address'>{content.shipping_address_label}</label></h2>
                <div className='address-select-wrap'>
                  <select disabled={configuration.lock_shipping_address} id='order-form-shipping-address' onChange={onChangeShippingAddress} value={order.shipping_address_id}>
                    {configuration.allow_shipping_override &&
                      <option value='custom-shipping'>-- Custom Shipping Address --</option>
                    }
                    {addresses.allIds.map(id => (
                      <option key={id} value={id}>{getAddressLabel(addresses.byId[id])}</option>
                    ))}
                  </select>
                  {shipping_address ?
                    (shipping_address.id === 'custom-shipping' ?
                      <AddressForm kind='shipping' address={shipping_address} />
                      :
                      <AddressPreview address={shipping_address} show_ship_instructions={true} />
                    )
                    :
                    <h4>Loading Shipping Address...</h4>
                  }
                </div>
              </div>
            </div>
          </div>
          <h2 className='items-heading'>{content.items_label}</h2>
          <div className='products'>
            {/* <ItemFields items={all_items.allIds} all_items={all_items} onBlur={onBlur} categories={categories} onChange={onChangeQuantity} onClick={onClick} onFocus={onFocus} order={order} /> */}
            <OrderItemInputs items={order.items.allIds} all_items={all_items} onBlur={onBlur} removeItemFromOrder={removeItemFromOrder} categories={categories} onChange={onChangeQuantity} onClick={onClick} onFocus={onFocus} order={order} />
          </div>
          <div className='add-items-subtotal'>
            <ItemPicker />
            <div className='subtotal'>
              <h3>Subtotal</h3>
              <span className='subtotal-value'>{formatCurrency(getSubtotal(order, all_items))}</span>
            </div>
            {
              display_free_shipping_difference && free_shipping_difference > 0 && free_shipping_difference <= display_free_shipping_difference_threshold &&
              <div className='free-shipping-difference'><span role='img' aria-label='light-bulb icon'>&#128161;</span> {content.displayFreeShippingDifference(formatCurrency(free_shipping_threshold - subtotal))}</div>
            }
            {
              display_free_shipping_difference && free_shipping_difference <= 0 &&
              <div className='free-shipping-difference'><span role='img' aria-label='check-mark icon'>&#9989;</span>{content.free_shipping_threshold_met}</div>
            }
          </div>
          <div className='shipping-information'>
            {/* <div className='show-shipping-on-preview'>Preview this order to view estimated Shipping & Handling and Tax information.</div> */}
            <div className='show-shipping-on-final-invoice'>{content.show_shipping_on_final_invoice}</div>
            {
              display_free_shipping_threshold &&
              <div className='display-free-shipping-threshold'>{content.displayFreeShippingThreshold(formatCurrency(free_shipping_threshold))}</div>
            }
            {/* <div className='order-summary-wrap'>
              <h2>Order Summary</h2>
              <OrderTotals items={items} order={order} />
            </div> */}
          </div>
          <div
            className='submit-fields'>
            <input type='reset' className='button' />
            <input
              type='submit'
              className='button'
              title={values(order.quantities).filter(q => q > 0).length < 1 ? 'Please order at least one item' : 'Checkout'}
              disabled={values(order.quantities).filter(q => q > 0).length < 1 || order.status !== 'editing' || show_item_picker}
              value={values(order.quantities).filter(q => q > 0).length < 1 ? 'Add items to order' : order_status_map[order.status]} />
          </div>
        </form>
        {primary_poc_name && primary_poc_email &&
          <div className='points-of-contact'>
            Please contact <a href={`mailto:${primary_poc_email}`} title={primary_poc_email}>{primary_poc_name}</a> {secondary_poc_name && secondary_poc_email && <span>or <a href={`mailto:${secondary_poc_email}`} title={secondary_poc_email}>{secondary_poc_name}</a></span>} with any questions.
        </div>
        }
      </div >
    );
  }
  else if (order.status === 'confirming') {
    return <OrderPreview props={ownProps} />
  }
  else if (order.status === 'submitting') {
    return (
      <div className='submitting order-form-wrap'>
        <h2>Submitting Order</h2>
        <h3>This process may take several seconds to complete.</h3>
        <LoadingAnimation />
        {/* add cancel button */}
      </div>
    )
  }
  else {
    return (<div>{order.status}</div>)
  }
}

export default OrderForm;