export const fetchZipCodes = ()=> {
  return new Promise((resolve, reject)=> {
    fetch('/zips.json', {cache: 'force-cache' }).then(response=>response.json()).then(data=>resolve(data))
  });
}

export const createAddressFromNSAddress = (nsaddress)=> {
  let address = {};
  address.id = nsaddress.internalid;
  address.internalid = nsaddress.internalid;
  address.line1 = nsaddress.addr1_initialvalue;
  address.line2 = nsaddress.addr2_initialvalue;
  address.line3 = nsaddress.addr3_initialvalue;
  address.addressee = nsaddress.addressee_initialvalue;
  address.attention = nsaddress.attention_initialvalue;
  address.city = nsaddress.city_initialvalue;
  address.country = nsaddress.country_initialvalue;
  address.is_default_shipping = nsaddress.defaultshipping;
  address.is_default_billing = nsaddress.defaultbilling;
  address.full_state = nsaddress.displaystate_initialvalue;
  address.state = nsaddress.dropdownstate_initialvalue;
  address.is_residential = nsaddress.isresidential;
  address.label = nsaddress.label;
  address.phone = nsaddress.phone_initialvalue;
  address.zip_code = nsaddress.zip_initialvalue;
  address.override_text = nsaddress.addressbookaddress_text;
  address.override_enabled = nsaddress.override_initialvalue === 'T';
  address.ship_instructions = nsaddress.custrecord_ship_instructions;
  address.saturday_delivery = nsaddress.custrecord_saturday_delivery;
  address.needs_delivery_appointment = nsaddress.custrecord_needs_delivery_appointment;
  address.inside_delivery = nsaddress.custrecord_inside_delivery;
  address.additional_handling = nsaddress.custrecord_additional_handling;
  address.lift_gate = nsaddress.custrecord_lift_gate;
  address.bol_instruction_1 = nsaddress.custrecord_bol_instruction_1;
  address.bol_instruction_2 = nsaddress.custrecord_bol_instruction_2;
  address.bol_instruction_3 = nsaddress.custrecord_bol_instruction_3;
  address.bol_instruction_4 = nsaddress.custrecord_bol_instruction_4;
  address.bol_instruction_5 = nsaddress.custrecord_bol_instruction_5;
  address.bol_instruction_6 = nsaddress.custrecord_bol_instruction_6;
  address.bol_instruction_7 = nsaddress.custrecord_bol_instruction_7;
  address.a1_shipment_options = nsaddress.custrecord_a1_shipment_options;
  return address;
}

export const getAddressLabel = (address) => {
  var label = "Address";
  var fields = [
    "label",
    "addressee",
    "attention",
    "line1",
    "line2",
    "line3",
    "phone",
    "city",
    "state",
    "full_state",
    "zip_code",
    "country",
    "id",
    "internalid",
  ];
  var index = 0;
  while(index < fields.length){
    if(address.hasOwnProperty(fields[index]) && address[fields[index]].toString().trim() !== ''){
      // label = `Address #${address.id} - ${address[fields[index]]}`;
      label = address[fields[index]];
      index = fields.length;
    }
    index++;
  }
  return label;
  }