import { connect } from 'react-redux';
import LoginForm from './LoginForm';
import { login } from '../../authActions';

const mapStateToProps = (state, ownProps) => ({
  from: state.auth.redirect_from,
  history: ownProps.history,
});


const mapDispatchToProps = (dispatch, ownProps) => ({
  onSubmit: (e) => {
    e.preventDefault();
    dispatch(login(e.target.elements.email.value, e.target.elements.password.value, e.target.elements.rememberme.value, ownProps.props.history, ownProps.props.location.state));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(LoginForm);