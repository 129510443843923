import { connect } from 'react-redux';
import Orders from './Orders';

export const mapStateToProps = (state) => ({
  orders: state.orders,
  visible_ids: state.orders.allIds,
});

export const mapDispatchToProps = (dispatch) => ({
  updateSearch: (e) => {
    var orders = document.querySelectorAll('.orders a');
    for (var i = 0; i < orders.length; i++) {
      try {
        if (!orders[i].innerText.toLowerCase().match(e.target.value.toLowerCase()) && !orders[i].className.match(/new/)) {
          orders[i].style.display = 'none';
        } else {
          orders[i].style.display = '';
        }
      }
      catch (e) {
        orders[i].style.display = '';
      }
    }
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(Orders);