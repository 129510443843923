import React from 'react';
import { Link } from 'react-router-dom';

const LoginForm = ({ onSubmit }) => (
  <form className='login-form' onSubmit={onSubmit} action='#'>
    <div className='field'>
      <label htmlFor='email'>E-mail: </label>
      <input name='login-email' id='email' type='email' defaultValue='' placeholder='user@example.com' autoComplete='email' required />
    </div>
    <div className='field'>
      <label htmlFor='password'>Password: </label>
      <input name='login-password' id='password' defaultValue='' type='password' placeholder='**********' autoComplete='current-password' required />
    </div>
    <div className='rememberme field'>
      <label htmlFor='rememberme'>Remember Me: </label>
      <input name='rememberme' id='rememberme' type='checkbox' />
    </div>
    <input type='submit' value='Log In' />
    <br />
    {/* <Link to='/forgot-password'>Forgot Password</Link> */}
  </form>
);

export default LoginForm;