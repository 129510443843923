import React from 'react';
import { Link } from 'react-router-dom';
import '../orderStyling.scss';
import './Orders.scss';
import { formatCurrency } from '../../app/appHelpers';
import LoadingAnimation from '../../app/animations/LoadingAnimation';
import { formatCustomerString } from '../orderHelpers';

const Orders = ({ orders, visible_ids, updateSearch }) => (
  <div className='orders-wrap'>
    <h1>Orders</h1>
    <div id='search-wrap'>
      <label htmlFor='search'>Quick Search: </label>
      <input id='search' type='search' onChange={updateSearch} placeholder='Enter query here' />
    </div>
    <div className='orders'>
      {
        orders && orders.allIds.length > 1 ?
          <div className='header'>
            <div className='sales-order-number'>Order Number</div>
            <div className='date'>Date</div>
            <div className='ship-date'>Ship Date</div>
            <div className='po-number'>PO#</div>
            <div className='ordered-by'>Ordered By</div>
            <div className='total'>Total</div>
            <div className='status-wrap'>
              <div className='status'>Status</div>
            </div>
          </div>
          : null}
      {orders && orders.allIds.length > 1 ?
        visible_ids.filter(id => id !== 'new').sort(id => orders.byId[id].date).reverse().map(id => {
          let order = orders.byId[id];
          // if(!order.locked){
          return (
            <Link to={[null, 'orders', id].join('/')} key={order.id} className={order.status}>
              <div className='sales-order-number'>{order.sales_order_number}</div>
              <div className='date'>{order.date}</div>
              <div className='ship-date'>{order.actual_ship_date || 'TBD'}</div>
              <div className='po-number'>{order.po_number}</div>
              <div className='ordered-by'>{formatCustomerString(order.ordered_by)}</div>
              <div className='total'>{formatCurrency(order.total)}</div>
              <div className='status-wrap'>
                <div className='status' data-status={order.status}>{order.status}</div>
              </div>
            </Link>
          )
          // }
        })
        :
        orders.error ? (<h2>Error loading orders. Please try again.</h2>) :
          <h2>{orders.loading && !orders.error ? (<div><div>Loading Orders</div><LoadingAnimation /></div>) : 'None created yet.'}</h2>
      }
    </div>
    <Link to='/orders/new' className='new button'>Create New order</Link>
  </div>
)

export default Orders;