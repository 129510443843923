export default {
  defaultCategory: 0,
  byId: {
    2500: {
      id: 2500,
      key: 'showerheads',
      name: 'Shower Heads',
    },
    4300: {
      id: 4300,
      key: 'thermometers',
      name: 'Thermometers',
    },
    4400: {
      id: 4400,
      key: 'thermostats',
      name: 'Thermostats',
    },
    8000: {
      id: 8000,
      key: 'led-bulbs',
      name: 'LED Bulbs',
    },
    6000: {
      id: 6000,
      key: 'caulk-and-caulk-guns',
      name: 'Caulk / Caulk Guns',
    },
    2700: {
      id: 2700,
      key: 'miscellaneous',
      name: 'Miscellaneous',
    },
    5500: {
      id: 5500,
      key: 'aerators',
      name: 'Aerators',
    },
    5600: {
      id: 5600,
      key: 'power-strips',
      name: 'Power Strips',
    },
    8500: {
      id: 8500,
      key: 'pipe-insulation',
      name: 'Pipe Insulation',
    },
    9500: {
      id: 9500,
      key: 'tank-wraps-ducts-and-filters',
      name: 'Tank Wraps Ducts & Filters',
    },
    1000: {
      id: 1000,
      key: 'weatherstripping',
      name: 'Weatherstripping',

    }
  },
  allIds: [0]
}