import React from 'react';
import './Order.scss';
import '../orderStyling.scss';
// import { formatCurrency } from '../../app/appHelpers';
import QuantifiedItems from '../OrderItems/QuantifiedItem/QuantifiedItems';
import OrderTotals from '../OrderTotals/OrderTotals';
import { ThankYou } from './ThankYou';
import LoadingAnimation from '../../app/animations/LoadingAnimation';
import { Link } from 'react-router-dom';
import AddressPreview from '../../addresses/AddressPreview';
import content from '../../../content';
import { formatCustomerString } from '../orderHelpers';

const Order = ({ order, items, shipping_address, billing_address, shipping_address_text, billing_address_text }) => {
  if (order) {
    if (order.status === 'submitting' || order.status === 'submitted') {
      return (
        <div className='order-wrap'>
          <Link to='/orders' className='back button'><span role='img' aria-label='back'>←</span>Back to orders</Link>
          <ThankYou order={order} />
          <h2><LoadingAnimation /></h2>
          {/* <div className='loading order'>
            <h2>Order #<LoadingAnimation /></h2>
            <a
              target='_blank'
              className='print button'
              rel='noopener noreferrer'
              href={`https://system.netsuite.com/app/accounting/print/hotprint.nl?regular=T&sethotprinter=T&formnumber=&whence=&printtype=transaction&trantype=salesord&recordtype=&label=Sales%20Order&id=${order.id}`}
            >🖶 Print</a>
            <div className='date' data-label='Date: '><LoadingAnimation /></div>
            <div className='status-wrap' data-label='Status: '><div className='status' data-status={order.status}>{order.status}</div></div>
            <div className='po-number' data-label='PO Number: '><LoadingAnimation /></div>
            <div className='comments' data-label='Comments: '><LoadingAnimation /></div>
            <div className='shipping-carrier' data-label='Shipping Carrier: '><LoadingAnimation /></div>
            <div className='shipping-method' data-label='Shipping Method: '><LoadingAnimation /></div>
            <div className='addresses'>
              <div className='billing-address' data-label='Billing Address: '>
                <AddressPreview loading={true} />
              </div>
              <div className='shipping-address' data-label='Shipping Address: '>
                <AddressPreview loading={true} />
              </div>
            </div>
            <div className='items' data-label='Items: '>
              <QuantifiedItems quantified_items={order.items} />
              <h3 className='loading-items-icon'><LoadingAnimation /></h3>
            </div>
            <div className='total'>
              <OrderTotals loading={true} />
            </div>
          </div> */}
        </div>
      );
    } else {
      return (
        <div className='order-wrap'>
          <Link to='/orders' className='back button'><span role='img' aria-label='back'>←</span>Back to orders</Link>
          {order.status === 'submitting' ? <ThankYou order={order} /> : null}
          <div className='order'>
            <h2>Order #{order.sales_order_number}</h2>
            {/* <a
              target='_blank'
              className='print button'
              rel='noopener noreferrer'
              href={`https://system.netsuite.com/app/accounting/print/hotprint.nl?regular=T&sethotprinter=T&formnumber=&whence=&printtype=transaction&trantype=salesord&recordtype=&label=Sales%20Order&id=${order.id}`}
            >🖶 Print</a> */}
            <div className='date' data-label='Date: '>{order.date}</div>
            <div className='customer' data-label='Customer: '>{order.customer_name ? formatCustomerString(order.customer_name) : null}</div>
            <div className='ordered-by' data-label='Ordered By: '>{order.ordered_by ? formatCustomerString(order.ordered_by) : '--'}</div>
            <div className='status-wrap' data-label='Status: '><div className='status' data-status={order.status}>{order.status}</div></div>
            <div className='po-number' data-label='PO Number: '>{order.po_number || '--'}</div>
            {/* <div className='memo' data-label='Memo: '>{order.memo || '--'}</div> */}
            <div className='ship-date' data-label='Ship Date: '>{order.actual_ship_date || 'TBD'}</div>
            <div className='tracking' data-label='Tracking Numbers: '>{order.trackingnumbers || '--'}</div>
            <div className='shipping-instructions' data-label='Shipping Instructions'>{order.shipping_address_notes || '--'}</div>
            {/* <div className='shipping-carrier' data-label='Shipping Carrier: '>{order.shipping_carrier}</div> */}
            {/* <div className='shipping-method' data-label='Shipping Method: '>{order.shipping_method}</div> */}
            <div className='addresses'>
              <div className='billing-address' data-label='Billing Address: '>
              { billing_address ? 
                <AddressPreview address={billing_address} />
                :
                <AddressPreview address_text={billing_address_text} />
              }
              </div>
              <div className='shipping-address' data-label='Shipping Address: '>
              { shipping_address ? 
                <AddressPreview address={shipping_address} />
                :
                <AddressPreview address_text={shipping_address_text} />
              }
              </div>
            </div>
            { order.items && order.items.length ?
              <div>
                <div className='items' data-label='Items: '>
                  <QuantifiedItems quantified_items={order.items} />
                </div>
                <div className='total'>
                  <OrderTotals order={order} items={items} estimate={false} />
                </div>
              </div>
              : null
            }
          </div>
          <div className='contact-about-order'>
            For questions, changes, or cancellations, please contact us at <a href={`mailto:${content.contact_email_address}?subject=Sales Order #${order.sales_order_number} on ${order.date}`}>{content.contact_email_address}</a>
          </div>
          {/* <div className='tracking-info'>
            <div className='tracking-number'>{order.trackingnumbers}</div>
          </div> */}
          {order.locked && 
            <iframe title='sales-order-preview' width='800' height='1100' src={`/api/v1/print/${order.id}`}></iframe>
          }
        </div>
      )
    }
  }
  else {
    return (
      <div>
        <h2>Loading Order</h2>
        <h3><LoadingAnimation /></h3>
      </div>
    )
  }
}


export default Order;