import { connect } from 'react-redux';
import ChangePassword from './ChangePassword';

import { updateValidity, changePassword } from '../authActions';

export const mapStateToProps = (state, ownProps)=> ({
  forced: state.auth.user_data.force_password_change,
  status: state.auth.status,
  email: state.auth.user_data.email,
  validity: state.auth.validity,
  ownProps,
});

export const mapDispatchToProps = (dispatch, ownProps) => ({
  onSubmit: (e)=> {
    e.preventDefault();
    dispatch(changePassword(e.target.elements.email.value, 
                            e.target.elements.current_password.value,
                            e.target.elements.new_password.value,
                            e.target.elements.new_password_confirm.value,
                            ownProps.history,
                            ownProps.location.state));
  },
  onChange: (e)=> {
    if(e.target.id === 'current-password'){
      document.getElementById('new-password').setCustomValidity('');
      document.getElementById('new-password-confirm').setCustomValidity('');
    }
    if(e.target.id === 'new-password'){
      dispatch(updateValidity('length', e.target.value.length >= 8));
      dispatch(updateValidity('lower', !!e.target.value.match(/[a-z]/)));
      dispatch(updateValidity('upper', !!e.target.value.match(/[A-Z]/)));
      if(document.getElementById('new-password-confirm').value.length > 0){
        dispatch(updateValidity('match', document.getElementById('new-password-confirm').value  === e.target.value));
      }
      // Make sure password is different
      if(e.target.value === document.getElementById('current-password').value){
        e.target.setCustomValidity('New password must be different from current password.');
      } else {
        e.target.setCustomValidity('');
      }
    }
    if(e.target.id === 'new-password-confirm'){
      if(e.target.value !== document.getElementById(e.target.dataset.matchId).value){
        e.target.setCustomValidity('Passwords must match.');
      } else {
        e.target.setCustomValidity('');
      }
      dispatch(updateValidity('match', e.target.value === document.getElementById(e.target.dataset.matchId).value));
      if(e.target.value.length === 0) {
        dispatch(updateValidity('match', undefined));
      }
    }
  },
  onFocus:(e)=> { 
  },
  onBlur: (e)=> {
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(ChangePassword);