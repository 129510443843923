import INITIAL_STATE from './productInitialState';
import {
  RECEIVE_PRODUCTS_FROM_API,
} from './productTypes';

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case RECEIVE_PRODUCTS_FROM_API: {
      return {
        ...state,
        ...action.products
      }
    }
    default: {
      return state;
    }
  }

}