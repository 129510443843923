import { connect } from 'react-redux';
import { OrderPreview } from './OrderPreview';
import {
  receiveConfirmOrderConfirmation,
  receiveConfirmOrderRejection,
  requestSubmitOrderToAPI, } from '../orderActions';

const joinOrderState = (state)=>{
  let order = { ...state.orders.byId[state.orders.currentOrderId] }
  order.shipping_address = state.addresses.byId[order.shipping_address_id];
  order.billing_address = state.addresses.byId[order.billing_address_id];
  order.customer_data = state.auth.user_data;
  order.items = Object.keys(order.quantities).map(id=>({
    ...state.products.byId[id],
    category: state.categories.byId[state.products.byId[id].category_id],
    quantity: order.quantities[id],
  })).filter(obj=>obj.quantity !== 0);
  return order;
}

export const mapStateToProps = (state, ownProps)=> ({
  free_shipping_threshold: state.orders.free_shipping_threshold || 2500,
  order: joinOrderState(state),
  show: state.orders.byId[state.orders.currentOrderId].status === 'confirming',
  items: state.products,
  free_shipping_enabled: true,
  history: ownProps.props.history,
});

export const mapDispatchToProps = (dispatch, ownProps)=> ({
  onConfirmOrder: (e)=> {
    dispatch(receiveConfirmOrderConfirmation());
    dispatch(requestSubmitOrderToAPI(ownProps.props.history));
  },
  onRejectConfirmOrder: (e)=> {
    dispatch(receiveConfirmOrderRejection());
  },
  onClickBackground: (e) => {
    if(e.target && e.target.id === 'order-preview-wrap'){
      dispatch(receiveConfirmOrderRejection());
    }
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(OrderPreview);