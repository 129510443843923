import { connect } from 'react-redux';
import Products from './Products';

export const mapStateToProps = (state) => ({
  products: state.products.allIds.map(id => state.products.byId[id]),
  categories: state.categories,
});

export const mapDispatchToProps = (dispatch) => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(Products);