import React from 'react';
import './Profile.scss';
import { Link } from 'react-router-dom';
import AddressPreview from '../addresses/AddressPreview';
import { getAddressLabel } from '../addresses/addressHelpers';
import LoadingAnimation from '../app/animations/LoadingAnimation';
import { formatCompanyName } from '../app/appHelpers';
// import { NETSUITE_ACCOUNT_ID } from '../../config';

const Profile = ({ user_data, addresses }) => (
  <div id='profile'>
    <h1>Profile</h1>
    {/* <div className='first-name' data-label='First Name'>{user_data.first_name}</div>
    <div className='last-name' data-label='Last Name'>{user_data.last_name}</div> */}
    <div className='company' data-label='Company'>{formatCompanyName(user_data.parent_company_name)}</div>
    <div className='email' data-label='E-mail'>{user_data.email}</div>
    {/*addresses.length < 1 ? (<p>Loading Addresses<LoadingAnimation /></p>) : addresses.map(address => {
      return (
        <div key={address.id} className='address' data-label={getAddressLabel(address)}>
          <AddressPreview address={address} />
        </div>
      )
    })*/}
    <Link to='/changepassword' className='button'>Change Password</Link>
    {/* <div className='netsuite'>
      <a href={`https://system.netsuite.com/app/login/secure/privatelogin.nl?c=${NETSUITE_ACCOUNT_ID}&redirect=%2Fapp%2Fcommon%2Fentity%2Fcustprofile.nl%3F`} className='button' target='_blank' rel="noopener noreferrer">Edit Your Profile</a>
    </div> */}
  </div>
);

export default Profile;