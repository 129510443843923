import {
  RECEIVE_CONFIGURATION_FROM_API,
  RECEIVE_CONFIGURATION_ERROR_FROM_API,
} from './configurationTypes';
import { API } from '../../config';
import { getCookieValue } from '../app/appHelpers';
import { receivePOValueFromAPI } from '../orders/orderActions';


export const receiveconfigurationFromAPI = (configuration) => ({
  type: RECEIVE_CONFIGURATION_FROM_API,
  configuration
});

export const receiveconfigurationErrorFromAPI = (configuration) => ({
  type: RECEIVE_CONFIGURATION_ERROR_FROM_API,
  configuration
});

export const requestConfigurationFromAPI = () => {
  return (dispatch) => {
    let xhr = new XMLHttpRequest();
    xhr.open('POST', API.CONFIGURATION_ENDPOINT);
    xhr.withCredentials = true;
    xhr.onload = () => {
      let configuration = JSON.parse(xhr.response).configuration;
      if (configuration) {
        dispatch(receiveconfigurationFromAPI(configuration));
        if (configuration.locked_po_value && configuration.locked_po_value.length) {
          dispatch(receivePOValueFromAPI(configuration.locked_po_value));
        }
        if (configuration.default_po_value && configuration.default_po_value.length) {
          dispatch(receivePOValueFromAPI(configuration.default_po_value));
        }
      } else {
        dispatch(receiveconfigurationErrorFromAPI(configuration));
      }
    }
    xhr.onerror = (e) => {
      console.log(e);
      // only do this for 401 errors
      // dispatch(clearLocalData());
    }
    let session_csrf = getCookieValue('session_csrf');
    xhr.send(JSON.stringify({ session_csrf }));
  }
};