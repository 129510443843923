import React from 'react';
// import LoadingAnimation from '../../app/_animations/LoadingAnimation';
import './ThankYou.scss';

export const ThankYou = ({ order }) => (
  <div id='thank-you'>
    <h1>Thank you for your order!</h1>
    <h2>{order.id !== 'submitting' ? `Order #${order.id} is being processed` : 'Your order is being submitted'}.</h2>
    <h2>This page will update with more information once your order has been sucessfully submitted.</h2>
    {/* <div className='loading'><LoadingAnimation /></div> */}
  </div>
);