export const updateQuantityById = (id) => {
  console.log(id)
}

export const getSubtotal = (order, items) => {
  return Object.keys(order.quantities).map(id => items.byId[id] !== undefined ? order.quantities[id] * items.byId[id].price : 0).reduce((prev, next) => prev + next, 0)
}

export const getShippingAndHandling = (order, items) => {
  let shipping_cost = 0.0;
  return Object.keys(order.quantities).map(id => items.byId[id] !== undefined ? order.quantities[id] * shipping_cost : 0).reduce((prev, next) => prev + next, 0)
}

export const getTax = (order, items) => {
  let tax_rate = 0.0;
  return getSubtotal(order, items) * tax_rate;
}

export const getTotal = (order, items) => {
  return getSubtotal(order, items) + getShippingAndHandling(order, items) + getTax(order, items);
} 

export const formatCustomerString = (input, include_parent)=> input ? input.split(' : ')[input.split(' : ').length - 1] : null

export const createOrderFromNSResult = (nsorder) => {
  let order = {};
  if (nsorder !== undefined && nsorder.result !== undefined) {
    order.items = nsorder.items;
    order.id = nsorder.result.id;
    if (nsorder.result !== undefined && nsorder.result.values !== undefined) {
      let values = nsorder.result.values;
      order.date = values.trandate;
      if (values.statusref !== undefined && values.statusref.length > 0 & values.statusref[0].text !== undefined) {
        order.status = values.statusref[0].text;
      }
      if (values.shipcarrier !== undefined && values.shipcarrier.length > 0) {
        order.shipping_carrier = values.shipcarrier[0].text;
      }
      if(values.shipmethod !== undefined && values.shipmethod.length > 0){
        order.shipping_method = values.shipmethod[0].text;
      }
      order.sales_order_number = values.tranid;
      order.total = values.total;
      order.po_number = values.otherrefnum;
      order.shipping_address_text = values.shipaddress;
      order.shipping_address_notes = values.custbody_ship_to_shipping_notes;
      order.billing_address_text = values.billaddress;
    }
  }
  return order;
}