import { connect } from 'react-redux';
import {
  updateAddressee,
  updateAttention,
  updatePhone,
  updateAddressLine1,
  updateAddressLine2,
  updateZipCode,
  updateCity,
  updateState,
} from '../addressActions.js'
import { fetchZipCodes } from '../addressHelpers';
import AddressForm from './AddressForm';


export const mapStateToProps = (state, props) => ({

});

const getAddressIdFromParents = (starting_node)=>{
  let target = starting_node;
  let addressId;
  while(addressId === undefined && target && target.nodeName.toUpperCase() !== 'BODY'){
    target = target.parentNode;
    if(target.dataset.hasOwnProperty('addressId')){
      addressId = target.dataset.addressId;
    }
  }
  return addressId;
}

export const mapDispatchToProps = (dispatch) => ({
  onChangeAttention: (e)=> {
    dispatch(updateAttention(getAddressIdFromParents(e.target), e.target.value));
  },
  onChangeAddressee: (e)=> {
    console.log(e.target);
    dispatch(updateAddressee(getAddressIdFromParents(e.target), e.target.value));
  },
  onChangePhone: (e)=> {
    dispatch(updatePhone(getAddressIdFromParents(e.target), e.target.value));
  },
  onChangeAddressLine1: (e)=> {
    dispatch(updateAddressLine1(getAddressIdFromParents(e.target), e.target.value));
  },
  onChangeAddressLine2: (e)=> {
    dispatch(updateAddressLine2(getAddressIdFromParents(e.target), e.target.value));
  },
  onChangeZipCode: (e)=> {
    let zip = e.target.value.substr(0,5);
    let address_id = getAddressIdFromParents(e.target);
    dispatch(updateZipCode(address_id, e.target.value));
    fetchZipCodes().then(zips=>{
      if(zip && zips.hasOwnProperty(zip)){
        dispatch(updateCity(address_id, zips[zip].split(',')[0]));
        dispatch(updateState(address_id, zips[zip].split(',')[1]));
      }
    })
  },
  onChangeCity: (e)=> {
    dispatch(updateCity(getAddressIdFromParents(e.target), e.target.value));
  },
  onChangeState: (e)=> {
    dispatch(updateState(getAddressIdFromParents(e.target), e.target.value));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(AddressForm);