import {
  REQUEST_ORDERS,
  RECEIVE_ORDERS,
  RECEIVE_ORDERS_ERROR_RESPONSE,
  REQUEST_SUBMIT_ORDER,
  RESET_STATE,
  UPDATE_QUANTITY,
  UPDATE_ORDER_PURCHASE_ORDER_NUMBER,
  UPDATE_ORDER_MEMO,
  UPDATE_CUSTOMER_COMMENTS,
  UPDATE_DEFAULT_ORDER_ADDRESSES,
  REQUEST_CONFIRM_ORDER_FROM_USER,
  RECEIVE_CONFIRM_ORDER_REJECTION_FROM_USER,
  RECEIVE_CONFIRM_ORDER_CONFIRMATION_FROM_USER,
  RECEIVE_ORDERS_FROM_API,
  RECEIVE_SUBMIT_ORDER_CONFIRMATION,
  UPDATE_CURRENT_BILLING_ADDRESS_ID,
  UPDATE_CURRENT_SHIPPING_ADDRESS_ID,
  RECEIVE_TAX_AND_SHIPPING_COSTS_DATA,
  RECEIVE_TAX_AND_SHIPPING_COSTS_ERROR,
  REQUEST_TAX_AND_SHIPPING_INFORMATION,
  RECEIVE_SUBMITTED_ORDER_DATA,
  SHOW_ITEM_PICKER,
  HIDE_ITEM_PICKER,
  ADD_ITEM_TO_CURRENT_ORDER,
  REMOVE_ITEM_FROM_CURRENT_ORDER,
  UPDATE_DEFAULT_BILLING_ADDRESS,
  UPDATE_DEFAULT_SHIPPING_ADDRESS,
  RECEIVE_PO_VALUE_FROM_API,
  RECEIVE_SUCCESSFUL_ORDER_TEST_DATA,
} from './orderTypes';
import { getCookieValue } from '../app/appHelpers';
import { clearLocalData } from '../auth/authActions';
import { API } from '../../config';


export const updatePurchaseOrderNumber = (id, value) => ({
  type: UPDATE_ORDER_PURCHASE_ORDER_NUMBER,
  id,
  value,
});

export const updateCustomerComments = (value) => ({
  type: UPDATE_CUSTOMER_COMMENTS,
  value,
});

export const updateOrderMemo = (id, value) => ({
  type: UPDATE_ORDER_MEMO,
  id,
  value,
});

export const updateQuantity = (id, value) => ({
  type: UPDATE_QUANTITY,
  id,
  value,
});

export const confirmOrder = () => ({
  type: REQUEST_CONFIRM_ORDER_FROM_USER,
})

export const resetState = (default_billing_address_id, default_shipping_address_id) => ({
  type: RESET_STATE,
  default_shipping_address_id,
  default_billing_address_id,
});

export const receiveConfirmOrderConfirmation = () => ({
  type: RECEIVE_CONFIRM_ORDER_CONFIRMATION_FROM_USER,
});

export const receiveConfirmOrderRejection = () => ({
  type: RECEIVE_CONFIRM_ORDER_REJECTION_FROM_USER,
});

export const requestOrders = () => ({
  type: REQUEST_ORDERS
});

export const receiveOrders = () => ({
  type: RECEIVE_ORDERS
});

export const receiveOrdersFromAPI = (orders) => ({
  type: RECEIVE_ORDERS_FROM_API,
  orders
});

export const receiveOrdersErrorResponse = (error) => ({
  type: RECEIVE_ORDERS_ERROR_RESPONSE,
  error,
});

export const requestSubmitOrder = () => ({
  type: REQUEST_SUBMIT_ORDER
})

export const receiveSubmitOrderConfirmation = () => ({
  type: RECEIVE_SUBMIT_ORDER_CONFIRMATION,
});

export const updateCurrentBillingAddressId = (id) => ({
  type: UPDATE_CURRENT_BILLING_ADDRESS_ID,
  id,
});

export const updateCurrentShippingAddressId = (id) => ({
  type: UPDATE_CURRENT_SHIPPING_ADDRESS_ID,
  id,
});

export const receiveTaxAndShippingCostsData = (data) => ({
  type: RECEIVE_TAX_AND_SHIPPING_COSTS_DATA,
  data,
});

export const receiveTaxAndShippingCostsError = (error) => ({
  type: RECEIVE_TAX_AND_SHIPPING_COSTS_ERROR,
  error,
});

export const requestTaxAndShippingInformation = () => ({
  type: REQUEST_TAX_AND_SHIPPING_INFORMATION
})

export const receiveSubmittedOrderData = (data) => ({
  type: RECEIVE_SUBMITTED_ORDER_DATA,
  data,
});

export const addItemToCurrentOrder = (id, quantity) => ({
  type: ADD_ITEM_TO_CURRENT_ORDER,
  id,
  quantity,
});

export const removeItemFromCurrentOrder = (id) => ({
  type: REMOVE_ITEM_FROM_CURRENT_ORDER,
  id,
})

export const showItemPicker = () => ({
  type: SHOW_ITEM_PICKER,
});

export const hideItemPicker = () => ({
  type: HIDE_ITEM_PICKER,
});

export const updateDefaultOrderAddresses = (default_address_id) => ({
  type: UPDATE_DEFAULT_ORDER_ADDRESSES,
  default_address_id,
});

export const updateDefaultBillingAddress = (address_id) => ({
  type: UPDATE_DEFAULT_BILLING_ADDRESS,
  address_id,
});

export const updateDefaultShippingAddress = (address_id) => ({
  type: UPDATE_DEFAULT_SHIPPING_ADDRESS,
  address_id,
});

export const receivePOValueFromAPI = (po_value) => ({
  type: RECEIVE_PO_VALUE_FROM_API,
  po_value,
})

export const receivedSuccessfulOrderTestData = (data) => ({
  type: RECEIVE_SUCCESSFUL_ORDER_TEST_DATA,
  data
});

export const requestOrdersFromAPI = (parent_id, force_update = false) => (
  (dispatch) => {
    dispatch(requestOrders());
    let xhr = new XMLHttpRequest();
    xhr.open('POST', API.ORDERS_ENDPOINT);
    xhr.withCredentials = true;
    xhr.onload = () => {
      let byId = {};
      JSON.parse(xhr.response).orders.forEach(order => byId[order.id] = order);
      let allIds = Object.keys(byId);
      let orders = {
        byId,
        allIds,
      }
      dispatch(receiveOrdersFromAPI(orders));
    }
    xhr.onerror = (error) => {
      console.log('Error requesting orders from API', error);
      if (error & error.hasOwnProperty('statusCode') && error.statusCode === 401) {
        dispatch(clearLocalData());
      }
      dispatch(receiveOrdersErrorResponse(error));
    };
    let session_csrf = getCookieValue('session_csrf');
    xhr.send(JSON.stringify({ session_csrf, force_update }));
  }
);

export const createOrder = (state) => {
  let order = state.orders.byId.new;
  let shipping_address = state.addresses.byId[order.shipping_address_id];
  let billing_address = state.addresses.byId[order.billing_address_id];
  return {
    save: false,
    email: (window.location.hostname !== 'localhost'),
    purchase_order_number: order.purchase_order_number,
    memo: order.memo,
    customer_id: state.auth.user_data.id,
    parent_id: state.auth.user_data.parent_id,
    fob: state.auth.user_data.fob,
    ordered_by_email: state.auth.user_data.email,
    parent_company_name: state.auth.user_data.parent_company_name,
    items: Object.keys(order.quantities).filter(item => item.quantity !== 0).map(itemid => ({
      location: state.products.byId[itemid].location,
      internalid: state.products.byId[itemid].item_id,
      sku: state.products.byId[itemid].location,
      label: state.products.byId[itemid].label,
      quantity: order.quantities[itemid],
      product_line: state.products.byId[itemid].product_line,
      customer_part_number_id: state.products.byId[itemid].customer_part_number_id,
      customer_part_number: state.products.byId[itemid].customer_part_number,
      price_level: state.products.byId[itemid].price_level,
    })),
    shipping_address: {
      label: shipping_address.label,
      id: shipping_address.internalid,
      country: shipping_address.country,
      isresidential: shipping_address.is_residential,
      attention: shipping_address.attention,
      addressee: shipping_address.addressee,
      addrphone: shipping_address.phone,
      addr1: shipping_address.line1,
      addr2: shipping_address.line2,
      city: shipping_address.city,
      state: shipping_address.state,
      zip: shipping_address.zip_code,
      ship_instructions: shipping_address.ship_instructions,
      saturday_delivery:shipping_address.saturday_delivery,
      needs_delivery_appointment: shipping_address.needs_delivery_appointment,
      inside_delivery: shipping_address.inside_delivery,
      additional_handling: shipping_address.additional_handling,
      lift_gate: shipping_address.lift_gate,
      bol_instruction_1: shipping_address.bol_instruction_1,
      bol_instruction_2: shipping_address.bol_instruction_2,
      bol_instruction_3: shipping_address.bol_instruction_3,
      bol_instruction_4: shipping_address.bol_instruction_4,
      bol_instruction_5: shipping_address.bol_instruction_5,
      bol_instruction_6: shipping_address.bol_instruction_6,
      bol_instruction_7: shipping_address.bol_instruction_7,
      a1_shipment_options: shipping_address.a1_shipment_options,
    },
    billing_address: {
      id: billing_address.internalid,
      country: billing_address.country,
      isresidential: billing_address.is_residential,
      attention: billing_address.attention,
      addressee: billing_address.addressee,
      addrphone: billing_address.phone,
      addr1: billing_address.line1,
      addr2: billing_address.line2,
      city: billing_address.city,
      state: billing_address.state,
      zip: billing_address.zip_code,
    }
  }
}

export const createOrderCopyWithLocation = (order, items, location, ship_combine_number) => {
  let new_order = {
    ...order,
    location,
    ship_combine: true,
    ship_combine_number
  };
  new_order.items = items;
  return new_order;
}

export const sendPostRequestToOrdersAPI = (state, save = false, onload, onerror) => {
  let order = createOrder(state);
  order.save = save;
  if (order.items.filter(item => !isNaN(parseInt(item.location)) && parseInt(item.location) !== 1).length) {
    let items_by_location = {};
    order.items.forEach(item => items_by_location[item.location || 1] === undefined ? items_by_location[item.location || 1] = [item] : items_by_location[item.location || 1].push(item));
    let byLocation = {};
    let ship_combine_number = (new Date()).toJSON().split('T')[0] + '-' + Math.random().toString(36).slice(6).toUpperCase();
    Object.keys(items_by_location).forEach(location => byLocation[location] = createOrderCopyWithLocation(order, items_by_location[location], location, ship_combine_number));
    let locations = Object.keys(byLocation);
    locations.forEach(location=>{
      let xhr = new XMLHttpRequest();
      xhr.open('POST', API.ORDERS_ENDPOINT);
      xhr.withCredentials = true;
      xhr.onload = () => { onload(xhr.response) }
      xhr.onerror = onerror
      let session_csrf = getCookieValue('session_csrf');
      let payload = byLocation[location];
      // console.log(JSON.stringify({ session_csrf, payload }));
      xhr.send(JSON.stringify({ session_csrf, payload }));
    })
  } else {
    let payload = order;
    let xhr = new XMLHttpRequest();
    xhr.open('POST', API.ORDERS_ENDPOINT);
    xhr.withCredentials = true;
    xhr.onload = () => { onload(xhr.response) }
    xhr.onerror = onerror
    let session_csrf = getCookieValue('session_csrf');
    // console.log(JSON.stringify({ session_csrf, payload }));
    xhr.send(JSON.stringify({ session_csrf, payload }));
  }
}

export const resetStateWithDefaultAddressFields = () => (
  (dispatch, getState) => {
    let state = getState();
    let default_billing_address_id = state.addresses.default_billing_address_id;
    let default_shipping_address_id = state.addresses.default_shipping_address_id;
    dispatch(resetState(default_billing_address_id, default_shipping_address_id));
  }
)

export const requestTaxAndShippingCostsFromAPI = () => (
  (dispatch, getState) => {
    dispatch(requestTaxAndShippingInformation());
    let state = getState();
    console.log('requestTaxAndShippingCostsFromAPI');
    sendPostRequestToOrdersAPI(state, false, (response) => {
      dispatch(receiveTaxAndShippingCostsData(JSON.parse(response)));
    }, (error) => {
      console.log('error', error);
      dispatch(receiveTaxAndShippingCostsError(error));
    });
  }
)

export const requestSubmitOrderToAPI = (history) => (
  (dispatch, getState) => {
    dispatch(requestSubmitOrder());
    let state = getState();
    // let userid = state.auth.user_data.id;
    let parent_id = state.auth.user_data.parent_id;
    let default_billing_address_id = state.addresses.allIds[0];
    let default_shipping_address_id = state.addresses.allIds[0];
    sendPostRequestToOrdersAPI(state, true, (response) => {
      response = JSON.parse(response);
      if (response['id'] !== undefined) {
        dispatch(receiveSubmittedOrderData(response));
        dispatch(resetState(default_billing_address_id, default_shipping_address_id));
        history.push(`/orders/${response.id}`);
        dispatch(requestOrdersFromAPI(parent_id, true));
      } else {
        dispatch(receivedSuccessfulOrderTestData(response));
        dispatch(resetState(default_billing_address_id, default_shipping_address_id));
        history.push(`/orders/new`);
      }
    }, (e) => {
      // check for 401 and
      // dispatch(clearLocalData());
      dispatch(receiveOrdersErrorResponse(e));
    });
  }
)
