import React from 'react';
// import ItemFieldsHeader from '../ItemFields/ItemFieldsHeader';
import './OrderItemModalPicker.scss';
import { formatCurrency, getDefaultImageSrc } from '../../../app/appHelpers';
import { getCategory } from '../../../categories/categoryHelper'; 

const ItemPicker = ({ onAddItem, filterItems, onSelectItem, onKeyUp, onFocusQuantity, onUpdateQuantity, onExitItemPicker, items, all_items, show_item_picker, categories, order }) => {
  if (show_item_picker) {
    return (
      <div className='item-picker-modal-wrap'>
        <div className='item-picker-modal' onClick={onExitItemPicker}>
          <div className='item-picker-wrap'>
            <h2>Select items</h2>
            <div id='search-wrap'>
              <label htmlFor='search'>Quick Search: </label>
              <input id='search' type='search' autoFocus onChange={filterItems} placeholder='Enter query here' />
            </div>
            <div className='item-picker-items-wrap'>
              <div className='item-picker'>
              <div className='header'>
                <div className='category'>Category</div>
                <div className='image'>Image</div>
                <div className='description'>Description</div>
                <div className='label'>Label</div>
                <div className='sku'>SKU</div>
                <div className='trm'>TRM</div>
                { all_items.allIds.filter(id=>all_items.byId[id].customer_part_number).length > 0 &&
                <div className='customer-sku'>Customer SKU</div>
                }
                <div className='price'>Price</div>
                <div className='case-quantity'>Case Size</div>
                <div className='quantity'>Item Quantity</div>
                {/* <div className='quantity'>Add Item</div> */}
              </div>
                {
                  all_items.allIds
                    .filter(id => items.indexOf(id) < 0)
                    .map(id => all_items.byId[id])
                    .sort((a, b) => 
                      (getCategory(a.category, 0) !== getCategory(b.category, 0) ? (getCategory(a.category, 0) < getCategory(b.category, 0) ? -1 : 1): 0) ||
                      (getCategory(a.category, 1) !== getCategory(b.category, 1) ? (getCategory(a.category, 1) < getCategory(b.category, 1) ? -1 : 1): 0) ||
                      (a.sku !== b.sku ? (a.sku < b.sku ? -1 : 1) : 0)
                    )
                    .map(item => (
                      <div className='item' key={item.internalid}>
                        {/* <div className='check'><input type='checkbox' /></div> */}
                        <div className='category'>{getCategory(item.category)}</div>
                        <div className='image'><img src={getDefaultImageSrc(item.image)} alt={item.label} /></div>
                        <div className='description'>{item.sales_description}</div>
                        <div className='label'>{item.label}</div>
                        <div className='sku'>{(item.virtual_sku && item.virtual_sku !== '' ? item.virtual_sku : item.sku)}</div>
                        <div className='trm'>{item.trm_number}</div>
                        { all_items.allIds.filter(id=>all_items.byId[id].customer_part_number).length > 0 &&
                          <div className='customer-sku'>{item.customer_part_number}</div>
                        }
                        <div className='price'>{formatCurrency(item.price)}</div>
                        <div className='case-quantity'>{item.case_quantity}</div>
                        <div className='quantity'>
                          <input
                            id={`prospective-quantity-${item.id}`}
                            data-item-id={item.id}
                            data-item-sku={item.sku}
                            type='number'
                            onKeyUp={onKeyUp}
                            defaultValue={0}
                            onFocus={onFocusQuantity}
                            step={item.quantity_step || 1}
                            min={0}
                            // max={item.quantity_max || Infinity}
                            // onChange={onUpdateQuantity}
                            className='quantity' />
                        </div>
                        {/* <div className='add'>
                          <button data-item-id={item.id} data-quantity-target={`prospective-quantity-${item.id}`} onClick={onSelectItem}>Add</button>
                        </div> */}
                      </div>
                    ))
                }
            </div>
            </div>
            <div className='item-picker-actions-wrap'>
              <button type='button' className='ok button'>OK</button>
              <button type='button' className='cancel button'>Cancel</button>
            </div>
          </div>
        </div>
      </div>
    )
  } else if (items.length < all_items.allIds.length) {
    return (
      <div className='item-picker-modal-wrap'>
        <div className='add'>
          <button type='button' onClick={onAddItem} className='button'><span role='img' aria-label='add an item'>➕</span> Add items</button>
        </div>
      </div>
    )
  } else if (show_item_picker) {
    return (
      <div>No Items Available</div>
    )
  } else {
    return null
  }
}

export default ItemPicker;