import React from 'react';
import { Link } from 'react-router-dom';
import { getCategory } from '../../categories/categoryHelper';
import { formatCurrency } from '../../app/appHelpers';
import './Products.scss';
import LoadingAnimation from '../../app/animations/LoadingAnimation';

const Products = ({ products, categories }) => {
  if (!products) {
    return (
      <div className='products'>
        <h2>Loading Products</h2>
        <LoadingAnimation />
      </div>
    );
  } else if(products.length === 0){
    return (
      <div className='products'>
        <h2>No Products to show.</h2>
      </div>
    );
  } else {
    return (
      <div className='products'>
        <h1>Products</h1>
        <div className='product-wrap header'>
          <div className='category-wrap'>Category</div>
          {/* <div className='image'>Image</div> */}
          { products.filter(product=>product.customer_part_number).length > 0 &&
            <div className='customer-part-number'>Customer Part Number</div>
          }
          <div className='label'>Name</div>
          <div className='sku'>SKU</div>
          <div className='description'>Description</div>
          <div className='case-size'>Case Quantity</div>
          <div className='trm-number'>TRM Number</div>
          {/* <div className='quantity-on-hand'>Total Quantity On Hand</div> */}
          <div className='price'>Price</div>
        </div>
        {products
          .sort((a, b) => 
            getCategory(a.category, 0).charCodeAt(0) - getCategory(b.category, 0).charCodeAt(0) ||
            getCategory(a.category, 1).charCodeAt(0) - getCategory(b.category, 1).charCodeAt(0) || 
            (a.sku < b.sku ? -1 : 1))
          .map(product => (
            <div className='product-wrap' key={product.id}>
              <div className='category-wrap'>
                {/* <Link to={`/products/${product.id}`} className='category'> */}
                <div className='category'>
                  {getCategory(product.category)}
                </div>
                {/* </Link> */}
              </div>
              {/* <div className='image'>
                <img src={getDefaultImageSrc(product.image)} alt={product.sku} />
              </div> */}
              { products.filter(product=>product.customer_part_number).length > 0 &&
                <div className='customer-part-number'>{product.customer_part_number}</div>
              }
              <div className='label'><Link to={`/products/${product.id}`} className='product'>{product.label}</Link></div>
              <div className='sku'>{(product.virtual_sku && product.virtual_sku !== '' ? product.virtual_sku : product.sku)}</div>
              <div className='description'>{product.sales_description}</div>
              <div className='case-size'>{product.case_quantity}</div>
              <div className='trm-number'>{product.trm_number}</div>
              {/* <div className='quantity-on-hand'>{Number(product.total_quantity_on_hand).toLocaleString()}</div> */}
              <div className='price'>{formatCurrency(product.price)}</div>
            </div>
          ))}
      </div>
    )
  }
}

export default Products;