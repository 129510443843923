import React from 'react';
import QuantifiedItem from './QuantifiedItem';
import { QuantifiedItemHeader } from './QuantifiedItem';

const QuantifiedItems = ({ quantified_items, display_customer_sku }) => (
  <div className='quantified-items'>
    <QuantifiedItemHeader display_customer_sku={display_customer_sku} />
    {quantified_items.map(quantified_item => <QuantifiedItem quantified_item={quantified_item} key={quantified_item.key} display_customer_sku={display_customer_sku}/>)}
  </div>
);

export default QuantifiedItems;