import React from 'react';
import LoadingAnimation from '../../app/animations/LoadingAnimation';
import { formatCurrency } from '../../app/appHelpers';
// import {
//   getShippingAndHandling,
//   getTax,
//   getTotal
// } from '../orderHelpers';
import './OrderTotals.scss';

const OrderTotals = ({ order, items, loading, estimate, display_shipping, free_shipping_enabled }) => (
  <div className='summary'>
  { display_shipping && 
    <div className='shipping-and-handling'>
      <h3>{estimate ? 'Est. ' : null }Shipping &amp; Handling</h3>
      <span className='shipping-and-handling-value'>{loading || order.shipping_and_tax_status === 'requesting' ? <LoadingAnimation /> : formatCurrency(order.shipping_total)}</span>
    </div>
  }
    <div className='shipping-and-handling'>
      <h3>{estimate ? 'Est. ' : null }Shipping &amp; Handling</h3>
      <span className='shipping-and-handling-value'>{free_shipping_enabled ? 'FREE' : 'TBD'}</span>
    </div>
    <div className='tax'>
      <h3>{estimate ? 'Est. ' : null }Tax</h3>
      <span className='tax-value'>{loading || order.shipping_and_tax_status === 'requesting' ? <LoadingAnimation /> : formatCurrency(order.tax_total === '' ? 0 : order.tax_total)}</span>
    </div>
    <div className='total-price'>
      <h3>{estimate ? 'Est. ' : null }Total Price</h3>
      <span className='total-price-value'>{loading || order.shipping_and_tax_status === 'requesting' ? <LoadingAnimation /> : formatCurrency(order.total)}</span>
    </div>
  </div>
);

export default OrderTotals;