export const REQUEST_ORDERS = 'REQUEST_ORDERS';
export const RESET_STATE = 'RESET_STATE';
export const UPDATE_QUANTITY = 'UPDATE_QUANTITY';
export const UPDATE_ORDER_PURCHASE_ORDER_NUMBER = 'UPDATE_ORDER_PURCHASE_ORDER_NUMBER';
export const UPDATE_ORDER_MEMO = 'UPDATE_ORDER_MEMO';
export const UPDATE_CUSTOMER_COMMENTS = 'UPDATE_CUSTOMER_COMMENTS';
export const REQUEST_CONFIRM_ORDER_FROM_USER = 'REQUEST_CONFIRM_ORDER_FROM_USER';
export const RECEIVE_CONFIRM_ORDER_CONFIRMATION_FROM_USER = 'RECEIVE_CONFIRM_ORDER_CONFIRMATION_FROM_USER';
export const RECEIVE_CONFIRM_ORDER_REJECTION_FROM_USER = 'RECEIVE_CONFIRM_ORDER_REJECTION_FROM_USER';
export const REQUEST_SUBMIT_ORDER = 'REQUEST_SUBMIT_ORDER';
export const RECEIVE_SUBMIT_ORDER_CONFIRMATION = 'RECEIVE_SUBMIT_ORDER_CONFIRMATION';
export const RECEIVE_SUBMIT_ORDER_REJECTION = 'RECEIVE_SUBMIT_ORDER_REJECTION';
export const RECEIVE_ORDERS_FROM_API = 'RECEIVE_ORDERS_FROM_API';
export const RECEIVE_ORDERS = 'RECEIVE_ORDERS';
export const RECEIVE_ORDERS_ERROR_RESPONSE = 'RECEIVE_ORDERS_ERROR_RESPONSE';
export const UPDATE_CURRENT_BILLING_ADDRESS_ID = 'UPDATE_CURRENT_BILLING_ADDRESS_ID';
export const UPDATE_CURRENT_SHIPPING_ADDRESS_ID = 'UPDATE_CURRENT_SHIPPING_ADDRESS_ID';
export const RECEIVE_TAX_AND_SHIPPING_COSTS_DATA = 'RECEIVE_TAX_AND_SHIPPING_COSTS_DATA';
export const RECEIVE_TAX_AND_SHIPPING_COSTS_ERROR = 'RECEIVE_TAX_AND_SHIPPING_COSTS_DATA';
export const REQUEST_TAX_AND_SHIPPING_INFORMATION = 'REQUEST_TAX_AND_SHIPPING_INFORMATION';
export const RECEIVE_SUBMITTED_ORDER_DATA = 'RECEIVE_SUBMITTED_ORDER_DATA';
export const ADD_ITEM_TO_CURRENT_ORDER = 'ADD_ITEM_TO_CURRENT_ORDER';
export const SHOW_ITEM_PICKER = 'SHOW_ITEM_PICKER';
export const HIDE_ITEM_PICKER = 'HIDE_ITEM_PICKER';
export const UPDATE_DEFAULT_ORDER_ADDRESSES = 'UPDATE_DEFAULT_ORDER_ADDRESSES';
export const REMOVE_ITEM_FROM_CURRENT_ORDER = 'REMOVE_ITEM_FROM_CURRENT_ORDER';
export const UPDATE_DEFAULT_BILLING_ADDRESS = 'UPDATE_DEFAULT_BILLING_ADDRESS';
export const UPDATE_DEFAULT_SHIPPING_ADDRESS = 'UPDATE_DEFAULT_SHIPPING_ADDRESS';
export const RECEIVE_PO_VALUE_FROM_API = 'RECEIVE_PO_VALUE_FROM_API';
export const RECEIVE_SUCCESSFUL_ORDER_TEST_DATA = 'RECEIVE_SUCCESSFUL_ORDER_TEST_DATA';