export const API = {
  CHANGE_PASSWORD_ENDPOINT: 'https://order.amconservationgroup.com/api/v1/changepassword',
  LOGIN_ENDPOINT: 'https://order.amconservationgroup.com/api/v1/login',
  ITEMS_ENDPOINT: 'https://order.amconservationgroup.com/api/v1/items',
  ORDERS_ENDPOINT: 'https://order.amconservationgroup.com/api/v1/orders',
  ADDRESSES_ENDPOINT: 'https://order.amconservationgroup.com/api/v1/addresses',
  CONFIGURATION_ENDPOINT: 'https://order.amconservationgroup.com/api/v1/configuration',
  PROJECTS_ENDPOINT: 'https://order.amconservationgroup.com/api/v1/projects',
};

export const NETSUITE_ACCOUNT_ID = (window.location.href.match(/localhost|test/) ? '4805853_SB1' : '4805853');
