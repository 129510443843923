import React from 'react';
import { Redirect } from 'react-router-dom';
import LoginForm from '../form/login/connectedLoginForm';
import { getActionMessageFromPathname } from './loginHelpers';
import LoadingAnimation from '../../app/animations/LoadingAnimation';
import '../../app/welcome/WelcomeMessage.scss';
import './Login.scss';

const Login = ({ redirect_to_referrer, is_authenticated, status, error_message, from, ownProps }) => {
  // Check for redirect
  if (redirect_to_referrer || is_authenticated) {
    return <Redirect to={from} />
  }
  if (status === 'requesting') {
    return (
      <div className='login'>
        <div className='welcome-message'>
          <h2>Welcome to the Trade Ally Ordering Portal.</h2>
          <p>Please log in below to {getActionMessageFromPathname(from.pathname)}</p>
        </div>
        <h2>Login</h2>
        {/* <p>You must log in to {getActionMessageFromPathname(from.pathname)}</p> */}
        <h3>Logging you in</h3>
        <LoadingAnimation />
      </div>
    )
  }
  else if (status === 'error') {
    return (
      <div className='login error'>
        <div className='welcome-message'>
          <h2>Welcome to the Trade Ally Ordering Portal.</h2>
          <p>Please log in below to {getActionMessageFromPathname(from.pathname)}</p>
        </div>
        <h2>Login</h2>
        <h3>Error logging in: </h3>
        <div className='error'>{error_message}</div>
        <LoginForm props={ownProps} />
      </div>
    )
  }
  else {
    return (
      <div className='login'>
        <div className='welcome-message'>
          <h2>Welcome to the Trade Ally Ordering Portal.</h2>
          <p>Please log in below to {getActionMessageFromPathname(from.pathname)}</p>
        </div>
        <h2>Login</h2>
        {/* <p>You must log in to {getActionMessageFromPathname(from.pathname)}</p> */}
        <LoginForm props={ownProps} />
      </div>
    )
  }
};

export default Login;