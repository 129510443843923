import { connect } from 'react-redux';
import Order from './Order';

const joinProductToCategoriesAndQuantity = (product, state, quantity) => ({
  ...product,
  category: state.categories.byId.hasOwnProperty(product.category_id) ? state.categories.byId[product.category_id] : {},
  quantity,
});

const getQuantity = (order, item_id)=> order.hasOwnProperty('quantities') ? order.quantities[item_id] : order.items.byId[item_id].quantity

const joinOrderToProducts = (order, state) => (order ? {
  ...order,
  items: order.items.allIds.map(item_id => state.products.byId.hasOwnProperty(item_id) ? joinProductToCategoriesAndQuantity(state.products.byId[item_id], state, getQuantity(order, item_id)) : order.items.byId[item_id]),
} : null);

export const mapStateToProps = (state, ownProps) => {
  let order = state.orders.byId[ownProps.match.params.id];
  if(order){
    if(order.locked){
      order.items = [];
    } else {
      order = joinOrderToProducts(order, state);
    }
    let shipping_address, billing_address, shipping_address_text, billing_address_text;
    if (order) {
      shipping_address = state.addresses.byId[order.shipping_address_id];
      billing_address = state.addresses.byId[order.billing_address_id];
      shipping_address_text = order.shipping_address;
      billing_address_text = order.billing_address;
    }
    return {
      order,
      items: state.products,
      shipping_address,
      shipping_address_text,
      billing_address,
      billing_address_text,
    }
  }
};

export const mapDispatchToProps = (dispatch) => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(Order);