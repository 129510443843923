import {
  RECEIVE_ADDRESSES_FROM_API,
  RECEIVE_ADDRESSES_ERROR_FROM_API,
  UPDATE_ADDRESSEE,
  UPDATE_ATTENTION,
  UPDATE_PHONE,
  UPDATE_ADDRESS_LINE_1,
  UPDATE_ADDRESS_LINE_2,
  UPDATE_ZIP_CODE,
  UPDATE_CITY,
  UPDATE_STATE,
} from './addressTypes';

import { createAddressFromNSAddress } from './addressHelpers';
// import { clearLocalData } from '../auth/authActions';
import { getCookieValue } from '../app/appHelpers';
import { updateDefaultShippingAddress, updateDefaultBillingAddress} from '../orders/orderActions';
import { API } from '../../config';



export const updateAddressee = (address_id, value) => ({
  type: UPDATE_ADDRESSEE,
  address_id,
  value
});

export const updateAttention = (address_id, value) => ({
  type: UPDATE_ATTENTION,
  address_id,
  value
});

export const updatePhone = (address_id, value) => ({
  type: UPDATE_PHONE,
  address_id,
  value
});

export const updateAddressLine1 = (address_id, value) => ({
  type: UPDATE_ADDRESS_LINE_1,
  address_id,
  value
});

export const updateAddressLine2 = (address_id, value) => ({
  type: UPDATE_ADDRESS_LINE_2,
  address_id,
  value
});

export const updateZipCode = (address_id, value) => ({
  type: UPDATE_ZIP_CODE,
  address_id,
  value
});

export const updateCity = (address_id, value) => ({
  type: UPDATE_CITY,
  address_id,
  value
});

export const updateState = (address_id, value) => ({
  type: UPDATE_STATE,
  address_id,
  value
});

export const receiveAddressesFromAPI = (addresses) => ({
  type: RECEIVE_ADDRESSES_FROM_API,
  addresses
});

export const receiveAddressErrorFromAPI = () => ({
  type: RECEIVE_ADDRESSES_ERROR_FROM_API
});

export const requestAddressesFromAPI = () => {
  return (dispatch) => {
    let xhr = new XMLHttpRequest();
    xhr.open('POST', API.ADDRESSES_ENDPOINT);
    xhr.withCredentials = true;
    xhr.onload = () => {
      let byId = {};
      let addresses_response = JSON.parse(xhr.response).addresses;
      if (addresses_response) {
        addresses_response.forEach(address_data => byId[address_data.id] = createAddressFromNSAddress(address_data));
        let allIds = Object.keys(byId);
        let addresses = {
          byId,
          allIds,
        }
        dispatch(receiveAddressesFromAPI(addresses));
        if (addresses.allIds.length > 0) {
          let default_shipping_address_id = addresses.allIds.filter(id=> addresses.byId[id].is_default_shipping === true)[0];
          let default_billing_address_id = addresses.allIds.filter(id=> addresses.byId[id].is_default_billing === true)[0];
          default_billing_address_id === undefined ? dispatch(updateDefaultBillingAddress(addresses.allIds[0])) : dispatch(updateDefaultBillingAddress(default_billing_address_id));
          default_shipping_address_id === undefined ? dispatch(updateDefaultShippingAddress(addresses.allIds[0])) : dispatch(updateDefaultShippingAddress(default_shipping_address_id));
        }
      } else {
        dispatch(receiveAddressErrorFromAPI());
      }
    }
    xhr.onerror = (e) => {
      console.log(e);
      // only do this for 401 errors
      // dispatch(clearLocalData());
    }
    let session_csrf = getCookieValue('session_csrf');
    xhr.send(JSON.stringify({ session_csrf }));
  }
};