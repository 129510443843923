// Import React
import React from 'react';

// React router
import {
  BrowserRouter as Router,
  Route,
  Link,
  Switch,
} from "react-router-dom";
import PrivateRoute from '../auth/PrivateRoute/connectedPrivateRoute';

// Import components
import Instructions from '../instructions/Instructions';
import Header from './header/connectedHeader';
import Home from '../home/connectedHome';
import OrderForm from '../orders/OrderForm/connectedOrderForm';
import Products from '../products/Products/connectedProducts';
import Product from '../products/Product/connectedProduct';
import Order from '../orders/Order/connectedOrder';
import Orders from '../orders/Orders/connectedOrders';
import Footer from './footer/connectedFooter';
import Login from '../auth/Login/connectedLogin';
import Profile from '../profile/connectedProfile';
import ChangePassword from '../auth/ChangePassword/connectedChangePassword';


// Import App styling
import './App.scss';

const NotFound = () => (
  <div className='404'>
    <h2>404 - Page Not Found</h2>
    <Link to='/'>Click here</Link> to visit the home page.
  </div>
)

const App = () => (
  <Router>
    <div className='app'>
      <Header />
      <main>
        <Switch>
          <Route path="/login" component={Login} />
          <Route path="/instructions" component={Instructions} />
          <PrivateRoute path="/changepassword" component={ChangePassword} />
          <PrivateRoute exact path='/' component={Home} />
          <PrivateRoute exact path='/profile' component={Profile} />
          <PrivateRoute exact path='/products' component={Products} />
          <PrivateRoute path='/products/:id' component={Product} />
          <PrivateRoute exact path='/orders' component={Orders} />
          <PrivateRoute exact path='/orders/new' component={OrderForm} />
          <PrivateRoute path='/orders/:id' component={Order} />
          <Route component={NotFound} />
        </Switch>
      </main>
      <Footer />
    </div>
  </Router>
);

export default App;