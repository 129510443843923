import { connect } from 'react-redux';
import PrivateRoute from './PrivateRoute';

const mapStateToProps = (state)=>({
  is_authenticated: state.auth.is_authenticated,
  needs_to_change_password: state.auth.user_data.force_password_change,
});

const mapDispatchToProps = (dispatch)=>({
  checkCookies: ()=>document.cookie.indexOf('session_data')>=0
});

export default connect(mapStateToProps, mapDispatchToProps)(PrivateRoute);