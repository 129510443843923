import React from 'react';
import './LoadingAnimation.scss';

const LoadingAnimation = () => (
  <span className='loading-icons'>
    <span id='a'>•</span>
    <span id='b'>•</span>
    <span id='c'>•</span>
  </span>
);

export default () => (
  <div id='loading-animation'>
    <LoadingAnimation />
  </div>
);