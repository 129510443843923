export default {
  allow_shipping_override: false,
  allow_billing_override: false,
  lock_billing_address: false,
  lock_shipping_address: false,
  locked_po_value: "",
  logo_image_url: "/am-logo.png",
  logo_line_1: "AM Conservation Group",
  logo_text_line_2: "Trade Ally Ordering Portal",
  portal_main_color: "#7eb0d7",
  portal_secondary_color: "#ea7423",
}