import React from 'react';
import { Link } from 'react-router-dom';
import './Product.scss';
import LoadingAnimation from '../../app/animations/LoadingAnimation';
import { formatCategory } from '../../categories/categoryHelper';

const Product = ({ product }) => {
  var property_map = {
    sku: 'SKU',
    customer_part_number: 'Customer SKU',
    label: 'Label',
    sales_description: 'Sales Description',
    // total_quantity_on_hand: 'Total Quantity On Hand',
    price: 'Price',
    case_quantity: 'Case Size',
  }
  if (product) {
    return (
      <div id='product' >
        <div className='back'>
          <Link to='/products' className='back button'><span role='img' arial-label='back'>←</span> Back to products</Link>
        </div>
        <div className='product'>
          <h1>Product</h1>
          <h2>{product.label}</h2>
          {
            Object.keys(property_map).filter(property=>product[property]!==undefined).map(property =>
              <div className={property + ' product-row'} key={property}>
                <div className='property'>{property_map[property]}</div>
                <div className='value'>{product[property]}</div>
              </div>
            )
          }
          <div className='category product-row'>
            <div className='property'>Category</div>
            <div className='value'>
              { formatCategory(product.category) }
            </div>
          </div>
        </div>
      </div>
    )
  } else {
    return (
      <div id='product'>
        Loading Product <LoadingAnimation />
      </div>
    )
  }
}

export default Product;