import React from 'react';

const OrderItemInputHeader = () => (
  <div className='item-heading field'>
    <div className='item-category-wrap'>Category</div>
    <div className='item-image-wrap'>Image</div>
    <div className='item-customer-sku-wrap'>Customer SKU</div>
    <div className='item-description'>Description</div>
    <div className='item-name-wrap'>Name</div>
    <div className='item-trm-number'>TRM Number</div>
    <div className='item-unit-price-wrap'>Unit Price</div>
    <div className='item-case-quantity-wrap'>Case Size</div>
    <div className='item-quantity-wrap'>Item Quantity</div>
    <div className='item-line-total-wrap'>Line Total</div>
  </div>
)

export default OrderItemInputHeader;