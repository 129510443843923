var constants = {
  contact_email_address: 'marketplacesupport@amconservationgroup.com',
};

var english_language_map = {
  ...constants,
  memo_label: 'Special Instructions',
  po_number_label: 'PO#',
  order_date_label: 'Order Date',
  customer_label: 'Customer',
  order_info_label: 'Order Info',
  billing_address_label: 'Billing Address',
  shipping_address_label: 'Shipping Address',
  items_label: 'Items',
  show_shipping_on_final_invoice: 'Shipping and taxes will be shown on final invoice.',
  displayFreeShippingThreshold: (threshold) => `Orders over ${threshold} qualify for free shipping.`,
  displayFreeShippingDifference: (difference) => `Only ${difference} away from qualifying for free shipping`,
  free_shipping_threshold_met: 'Your order qualifies for free shipping. Thank you for your business.',
};

const language_map = {
  'en-US': english_language_map,
  es: {
    ...english_language_map,
    memo_label: 'Instrucciones Especiales',
    po_number_label: 'Orden de Compra',
    order_date_label: 'Fecha de Orden',
    customer_label: 'Cliente',
    order_info_label: 'Información del Pedido',
    billing_address_label: 'Dirección de Envio',
    shipping_address_label: 'Facturación',
    items_label: 'Artículos',
    show_shipping_on_final_invoice: 'Los gastos de envío e impuestos se mostrarán en la factura final.',
    displayFreeShippingThreshold: (threshold) => `Los pedidos de más de ${threshold} califican para envío gratuito.`,

  },
};

export default ((lang)=>(language_map[lang]))(navigator.language && navigator.language in language_map  ? navigator.language : 'en-US');