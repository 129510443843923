import React from 'react';
import { Link } from 'react-router-dom';
import './Nav.scss';

const Nav = ({ current, main_color }) => {
  const getCurrentClass = (current, match, test) => test ? 'current' : current === match ? 'current' : null
  const getStyle = (is_current)=> is_current && main_color ? { backgroundColor: main_color, borderColor: main_color } : undefined
  return (
    <nav>
      <Link to='/' className={getCurrentClass(current, null, (current === '' || current === undefined))} style={getStyle((current === '' || current === undefined))}>Home</Link>
      <Link to='/orders' className={getCurrentClass(current, 'orders')} style={getStyle(current === 'orders')}>Orders</Link>
      <Link to='/products' className={getCurrentClass(current, 'products')} style={getStyle(current === 'products')}>Products</Link>
      <Link to='/profile' className={getCurrentClass(current, 'profile')} style={getStyle(current === 'profile')}>Profile</Link>
    </nav>
  );
}

export default Nav;