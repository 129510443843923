export const getFriendlyErrorMessage = (error) => {
  if (error === undefined || error === null) {
    return 'We are currently unable to process your request. Please check your internet connection and try again.';
  }
  else {
    if(error.message.indexOf('502 Bad Gateway') >= 0){
      return 'Error establshing connection to remote resource. Please try again.'
    }
    switch (error.message) {
      case 'Failed to fetch': {
        return 'Error retrieving data from remote resource. Please try again.';
      }
      case 'Error retrieving data from remote resource.': {
        return 'The login operation timed out. Please check your internet connection and try again.'
      }
      default: {
        return error.message;
      }
    }
  }
}