
export const createProductFromNSItem = (item) => {
  let product = {};
  if (item.price_data && item.price_data.price && item.price_data.price !== '') {
    console.log(item.id, item.price_data);
    product.internalid = item.id;
    product.id = item.id;
    product.item_id = item.item_id;
    product.sku = item.sku;
    product.virtual_sku = item.virtual_sku;
    product.location = item.location;
    product.customer_part_number = item.customer_part_number;
    product.customer_part_number_id = item.customer_part_number_id;
    product.key = item.id;
    product.label = item.label;
    product.trm_number = item.trm_number;
    product.sales_description = item.sales_description;
    product.total_quantity_on_hand = item.total_quantity_on_hand;
    product.category = item.category;
    product.category_id = item.category.split(' : ')[1].split(' ')[0];
    product.product_line = item.product_line;
    product.price_level = item.price_data.price_level;
    product.image = item.image;
    product.price = item.price_data.price;
    product.currency = item.price_data.currency;
    product.quantity_min = item.case_quantity;
    product.quantity_step = item.case_quantity;
    product.quantity_max = item.total_quantity_on_hand;
    product.total_quantity_on_hand = item.total_quantity_on_hand;
    product.case_quantity = item.case_quantity;
    if(parseFloat(product.case_quantity) === 0 || isNaN(parseFloat(product.case_quantity))){
      product.case_quantity = 1;
    }
    return product;
  }
  return null;
}