import React from 'react';
import { Route, Redirect } from 'react-router-dom';

const PrivateRoute = ({ component: Component, checkCookies, is_authenticated, needs_to_change_password, ...rest })=> (
  <Route
    {...rest}
    render={props =>
      is_authenticated && (!needs_to_change_password || props.location.pathname === '/changepassword') ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: (is_authenticated && needs_to_change_password ? '/changepassword' : '/login'),
            state: { from: props.location }
          }}
        />
      )
    }
  />
);

export default PrivateRoute;