import React from 'react';
import {
  withRouter,
  Link
} from 'react-router-dom';

const User = ({ is_authenticated, user_data, onClick }) => (
  is_authenticated && user_data ? (
    <div id='user'>
      {/* <div className='name'>{user_data.name}</div> */}
      <Link to='/profile'>{user_data.email}</Link>
      <button
        onClick={onClick}> Sign out </button>
    </div>
  ) : (
      <div id='user'>
        <Link to='/login'>login</Link>
      </div>
    )
);
export default withRouter(User);